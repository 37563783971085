import React, { useEffect, useReducer } from "react";
import styled from "styled-components";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ViewContainer = styled.div`
width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
  background-size: 800% 800%;
  -webkit-animation: BackgroundGradient 50s ease infinite;
  -moz-animation: BackgroundGradient 50s ease infinite;
  animation: BackgroundGradient 50s ease infinite;

@-webkit-keyframes BackgroundGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes BackgroundGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes BackgroundGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
`;

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  
`;

const InputField = styled.div`
  margin: 10px 20px;
  line-height: 30px;
  font-size: 15px;
  display: flex;
  flex-flow: column;
`;

const initialLoginState = {
  username: "",
  password: "",
  loading: false,
  allowPut: false,
  logged: false,
  loginError: "",
}

const loginReducer = (state, action) => {
  const actions = {
    "FORM_INPUT": () => {
      return {
        ...state,
        [action.field]: action.value
      }
    },
    "LOGIN": () => {
      return {
        ...state,
        logged: true
      }
    },
    "LOGIN_ERROR": () => {
      return {
        ...state,
        loginError: action.value
      }
    },
    "LOADING": () => {
      return {
        ...state,
        loading: true
      }
    },
    "LOADED": () => {
      return {
        ...state,
        loading: false
      }
    },
    "CLEAN_FORM": () => {
      return {
        ...state,
        create: {
          ...action.create,
        },
        update: false,
      }
    },
    "ALLOW_PUT": () => {
      return {
        ...state,
        allowPut: true
      };
    },
    "BLOCK_PUT": () => {
      return {
        ...state,
        allowPut: false
      };
    },
    "default": () => ({ ...state })
  }

  return actions[action.type] ? actions[action.type]() : actions["default"]();
}

export default ({ children }) => {

  const [loginState, loginDispatch] = useReducer(loginReducer, initialLoginState);
  const { username, password, loginError, logged } = loginState;


  useEffect(() => {
    const token = cookies.get('signedin');
    if (token) {
      fetch("/login/validate",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        })
        .then(data => data.json())
        .then(response => {
          if (response.validated) {
            loginDispatch({ type: "LOGIN" })
          }
        })

    }
  }, []);

  const postLogin = () => {
    const loginData = { username, password };
    const token = cookies.get("signedin");
    fetch("/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          credentials: "include",
          "Set-Cookie": token
        },
        body: JSON.stringify(loginData)
      })
      .then(data => data.json())
      .then(response => {
        if (!response.login) {
          return loginDispatch({ type: "LOGIN_ERROR", loginError: response.status })
        }
        cookies.set("signedin", response.token, { path: '/' });
        return loginDispatch({ type: "LOGIN" })
      })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    postLogin()
  }

  return logged ? children :
    <ViewContainer>

      <Form onSubmit={onSubmit} method="POST" enctype="multipart/form-data">

        <InputField class="input-field">
          <label for="username">username:</label>
          <input type="text" id="username" name="username" value={username} onChange={(e) => loginDispatch({ type: "FORM_INPUT", field: "username", value: e.target.value })} />
        </InputField>

        <InputField class="input-field">
          <label for="password">password:</label>
          <input type="password" id="password" name="password" value={password} onChange={(e) => loginDispatch({ type: "FORM_INPUT", field: "password", value: e.target.value })} />
        </InputField>

        <input class="submit-btn" type="submit" value="Login" onClick={postLogin} />
      </ Form>
    </ViewContainer>

}