import React, { useEffect, useReducer } from "react";


export const TimedPosesContext = React.createContext();


const initialState = {
  images: [],
  interval: 2,
}

const reducer = (state, action) => {
  const actions = {
    "GET_POSES": () => ({
      ...state,
      images: [...state.images, action.image],
    }),
    "INPUT": () => ({
      ...state,
      [action.field]: action.value
    }),
    "REORDER_POSES": () => ({
      ...state,
      images: action.images,
    }),

    "default": () => ({ ...state })
  };


  return actions[action.type] ? actions[action.type]() : actions["default"]();
}

export default () => {

  const [timedPosesState, timedPosesDispatch] = useReducer(reducer, initialState);

  return ({ timedPosesState, timedPosesDispatch });
}