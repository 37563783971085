import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";

import InfoContainer from "../components/InfoContainer";

import Footer from "../components/Footer";
import { animateScroll as scroll } from 'react-scroll'

import { ImagesContainer } from "./GalleryOverview";

import { Title } from "../components/ContactInfo";

import PhoneFlip from "../components/PhoneFlip";
import { deviceCheck, parseTagNameToTitle } from "../utils";


const Container = styled.div`
  width: 100vw;
  /* height: 100vh; */
  /* overflow: auto; */
  position: relative;
  display: flex;
  flex-flow: column nowrap;
`;

const Positioner = styled.div`
  /* position: ${props => props.stop ? "absolute" : "fixed"}; */
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* align-items:  ${props => props.stop ? "center" : "flex-start"};; */
  justify-content: center;
  gap: 40px;
  /* padding: 20px; */
  /* padding-top: 80px; */
  margin-bottom: 60px;  
  transition: 0.5s background-color ease, 1s bottom ease 0.5s;

  @media only screen and (max-width: 700px) {
  /* padding-top: 80px; */

  }

  ${props => props.fullScreen && `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    z-index: 1000;
    margin: 0;


    & > .details-container {
      display: none;
    }

    & > img {
      width: 98vw;
      object-fit: contain;
      height: 98vh;
    }
  `}
`;

const RelatedImage = styled.img`
  max-width: calc(100vw - 40px);
  object-fit: cover;
  transition: 1s all ease;
  transition-delay: 0.2s;
  opacity: 0;
  transform: translate(0, 50%) scale(0.95);

  @media only screen and (min-width: 700px) {
    width: 40vw;
    height: 60vw;
  }
  @media only screen and (min-width: 1200px) {
    width: 30vw;
    height: 50vw;
  }

  &.active{
    opacity: 1;
    transform: translate(0,0) scale(1);
  }
  
`;

const ImgBox = styled.div`
  position: relative;
  width: fit-content;
  ${props => props.fullScreen ?
    `
    cursor: zoom-out;
  `: `
    cursor: zoom-in;
  
  `}
`;

const StyledImage = styled.img`
  width: ${props => props.orientation === "landscape" ? props.fullScreen ? "90vw" : "50vw" : "auto"};
  height: ${props => props.orientation !== "landscape" ? props.fullScreen ? "90vh" : "80vh" : "auto"};
  opacity: 0;
  transition: 1s all ease;
  transform: translate(0, 50%) scale(0.95);
  animation: show-up 0.5s ease-in forwards;
  object-fit: contain;
  max-height: 90vh;
  position: relative;
  
  ${props => props.fullScreen && `
  width: ${props => props.orientation === "landscape" ? "90vw" : "auto"};
  height: ${props => props.orientation === "landscape" ? "auto" : "90vh"};

  `}
  

  @media only screen and (min-width: 1200px) {
    /* width: ${props => props.orientation === "landscape" ? "50vw" : "auto"}; */
  }
  
  @media only screen and (max-width: 700px) {
    width: calc(100vw - 40px);
    height: auto
  }

  @keyframes show-up {
    100%{
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }
`

const DetailsContainer = styled.div`
    /* padding: 20px 0; */
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    gap: 20px;
    width: -webkit-fill-available;

    @media only screen and (min-width: 700px) {
      width: 90vw;
    }
    @media only screen and (min-width: 1200px) {
      width: 70vw;
    }
`;

const RelatedImageryContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  gap: 5vw;
  padding: 0 2.5vw;
  /* margin-top: 200px; */
  /* gap: 50px; */
  margin-bottom: 80px;
  
  @media only screen and (min-width: 700px) {
    justify-content: flex-start;

  }
  @media only screen and (min-width: 1200px) {
    gap: 5vw;
    padding: 0 2.5vw;
    
  }
  
`;

const RestyledTitle = styled(Title)`
  margin: 0;
  text-transform: none;
  align-self: flex-start;
  text-indent: 20px;
  cursor: pointer;

  @media only screen and (min-width: 700px) {
    text-indent: none;
  }
`;

const PhoneContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
`;

const StyledExpand = styled.svg`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1;
  background-color: #ffffff30;
  transition: 2s opacity ease 1s;
${props => props.imageLoaded ? `
  opacity: 1;
  ` :
    `
  opacity: 0;
  `}
`;

const ExpandIcon = ({ fullScreen, ...props }) => fullScreen ?
  <StyledExpand width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ ...props }}>
    <g clip-path="url(#clip0)">
      <path d="M22.605 10.2749L10.275 22.6049" stroke="#2B2B2B" stroke-opacity="0.9" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2749 10.2749L22.6049 22.6049" stroke="#2B2B2B" stroke-opacity="0.9" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="23.2496" height="23.2496" fill="white" transform="translate(16.4399) rotate(45)" />
      </clipPath>
    </defs>
  </StyledExpand> :
  <StyledExpand width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ ...props }}>
    <path d="M17.1248 10.2749L22.6047 10.2749L22.6047 15.7549" stroke="#2B2B2B" stroke-opacity="0.9" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.2749 17.125L10.2749 22.605L15.7549 22.605" stroke="#2B2B2B" stroke-opacity="0.9" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.605 10.2749L10.275 22.6049" stroke="#2B2B2B" stroke-opacity="0.9" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
  </StyledExpand>

export default ({ }) => {


  const getId = (location) => {
    const hash = location.hash;
    const idUrl = hash ? hash.replace("#", "") : 0;
    return detailsId ? detailsId : idUrl;
  }
  const history = useHistory();

  const { globalContext: { allArtworks, detailsId, imagesList, entries }, globalDispatch } = useContext(DataContext);
  const [currentArtwork, setCurrentArtwork] = useState(getId(history.location))

  const currentEntry = useMemo(() => {
    return entries.find(entry => entry.fields.id === currentArtwork)
  }, [entries, currentArtwork]);

  const [viewOffsetTop, setViewOffsetTop] = useState(0);
  const container = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);

  const positioner = useRef();


  const [orientation, setOrientation] = useState(window.orientation)

  const artworkImageDimensions = useMemo(() => {
    if (currentEntry) {
      const dimensions = currentEntry.fields.image.fields.file.details.image;
      let orientation = dimensions.width > dimensions.height ? "landscape" : "portrait";

      if (deviceCheck() === "mobile") {
        return ({
          width: window.innerWidth,
          height: window.innerHeight,
          orientation
        })
      }

      const width = Math.round(window.innerHeight * 0.8);
      const height = Math.round(window.innerWidth * 0.5);

      return ({ width, height, orientation });
    }
  }, [currentEntry]);


  useEffect(() => {
    if (fullScreen && document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }

    }
  }, [fullScreen])

  useEffect(() => {
    globalDispatch({ type: "CHANGE_HEADER_COLOR", color: "var(--background-color)" });
  }, [])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const id = getId(location);
      setCurrentArtwork(id);
      scroll.scrollToTop({
        duration: 1000,
        smooth: true,
      });
    })

    return () => unlisten();
  }, [])

  const realtedTags = useMemo(() => {
    if (currentEntry) {

      const currentTags = currentEntry.metadata.tags.map(tag => tag.sys.id);
      const size = 2;
      const getRandomByTags = (tag) => {
        const filtered = entries.filter((entry) => entry.fields.id !== currentEntry.fields.id && entry.metadata.tags.find(tag => currentTags.includes(tag.sys.id)));
        if (filtered.length === 0) return [];
        const randomPicks = [];
        for (let i = 0; i < size; i++) {
          const randomIndex = Math.floor(Math.random() * (filtered.length - 1));
          randomPicks.push(filtered[randomIndex]);
          filtered.splice(randomIndex, 1);
        }
        return randomPicks
      }

      return currentTags.map(tag => ({
        tag,
        entries: getRandomByTags(tag)
      }))
    } else {
      return []
    }
  }, [currentEntry])

  useEffect(() => {
    if (viewOffsetTop > 200) {

    }
  }, [viewOffsetTop])

  useEffect(() => {

    const checkOrientation = function () {
      setOrientation(window.orientation);
    };
    window.addEventListener("orientationchange", checkOrientation, false);
  }, [])

  useEffect(() => {

    const scrollListener = () => {
      const relatedImagery = document.querySelectorAll(".related-image");
      relatedImagery.forEach(sliderImage => {

        const slideInAt = (window.scrollY + window.innerHeight) - sliderImage.height / 2;
        // bottom of the image
        const imageBottom = sliderImage.offsetTop + sliderImage.height;
        const isHalfShown = slideInAt > sliderImage.offsetTop;
        const isNotScrolledPast = window.scrollY < imageBottom;
        if (isHalfShown && isNotScrolledPast) {
          sliderImage.classList.add('active');
        } else {
        }
      })
    }
    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, [])

  return (
    <Container ref={container}>
      {currentEntry &&
        <>
          <Positioner ref={positioner} onClick={() => setFullScreen(!fullScreen)} stop={viewOffsetTop > 10} {...{ fullScreen }}>
            {fullScreen && artworkImageDimensions.orientation === "landscape" && window.innerWidth < 700 && orientation === 0 ?
              <PhoneContainer><PhoneFlip /></PhoneContainer> : <></>}
            <ImgBox {...{ fullScreen }}>
              <ExpandIcon {...{ imageLoaded, fullScreen }} />
              <StyledImage
                onLoad={() => {
                  setImageLoaded(true)
                }}
                loading="lazy"
                alt={currentEntry.fields.description}
                src={
                  !fullScreen ?
                    artworkImageDimensions.orientation === "landscape" ?
                      currentEntry.fields.image.fields.file.url + `?fm=jpg&fl=progressive&w=${artworkImageDimensions.width}`
                      : currentEntry.fields.image.fields.file.url + `?fm=jpg&fl=progressive&h=${artworkImageDimensions.height}` :
                    currentEntry.fields.image.fields.file.url + "?q=100"
                }
                orientation={artworkImageDimensions.orientation}
                {...{ fullScreen }}
              />
            </ImgBox>
            <DetailsContainer
              className="details-container"
              color={viewOffsetTop < 10 && "white"}
            >

              <InfoContainer shrink={viewOffsetTop > 10} artwork={currentEntry} />

            </DetailsContainer>

          </Positioner>
          <ImagesContainer>
            {realtedTags.map(({ tag, entries }) => !entries.length ? <></> :
              <>
                <RestyledTitle onClick={() => history.push("/gallery/" + tag)}>More <strong>{parseTagNameToTitle(tag)}</strong></RestyledTitle>
                <RelatedImageryContainer>
                  {entries
                    .map(entry =>
                      <RelatedImage
                        className="related-image"
                        alt={entry.fields.description}
                        onClick={() => {
                          history.push("/artwork#" + entry.fields.id)
                        }}
                        src={entry.fields.image.fields.file.url}
                      />
                    )}
                </RelatedImageryContainer>
              </>
            )}
          </ImagesContainer>
        </>

      }
      <Footer />
    </Container >
  )
}
