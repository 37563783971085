import React from "react";
import styled from "styled-components";
import { StyledAnchor } from "./Heading";


export const Title = styled.p`
  font-family: var(--label-font-family);
  font-size: 22px;
  margin: 60px 0 15px 0;
  line-height: 24px;
  text-transform: capitalize;

  ${props => props.contactPopOver && `
    margin: 20px 0;
  `}

  `;

export const Description = styled.p`
  font-family: var(--label-font-family);
  font-size: 16px;
  margin: 0;
  line-height: 24px;

`;

export const RestyledAnchor = styled(StyledAnchor)`
  font-weight: bolder;
  text-decoration: underline;
`;

const RestyledDescription = styled(Description)`
  white-space: nowrap;
  text-align: center;
`;

export default ({ contactPopOver }) => {

  return (
    <>
      <Title {...{ contactPopOver }}>Want to buy an original?</Title>
      <RestyledDescription>To buy originals, ask for comissions</RestyledDescription>
      <RestyledDescription>or just to say hello</RestyledDescription>
      <RestyledDescription> You can send me an email at</RestyledDescription>
      <RestyledDescription> <RestyledAnchor href="mailto: contact@andregirao.com">contact@andregirao.com</RestyledAnchor></RestyledDescription>
      <Title {...{ contactPopOver }}>Want to get a print?</Title>
      {/* <RestyledDescription>You can order prints directly from</RestyledDescription> */}
      <RestyledDescription> You can order them <RestyledAnchor href="https://www.artmajeur.com/andre-girao">in this link</RestyledAnchor></RestyledDescription>
      <Title {...{ contactPopOver }}>I am also on Instagram</Title>
      <RestyledDescription>For daily updates <br></br> follow me at <RestyledAnchor href="https://www.instagram.com/giraotauffer/">@giraotauffer</RestyledAnchor></RestyledDescription>
    </>
  )
}