import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { DataContext } from "./DataContext";
import "./Heading.css";

import { useState } from "react";
import { useContext } from "react";

import { useMemo } from "react";

import { copyToClipboard } from "../utils";
import { useHistory } from "react-router-dom";

const device = () => window.innerWidth < 700 ? "mobile" : "desktop";

const StyledFilter = styled.svg`
  height: 42px;
  width: 42px;
  cursor: pointer;
  &:hover{
    & path {
      stroke: black;
      /* stroke-width: 10px; */
      fill: black;
    }
  }
  & path {
  transition: fill 0.5s ease;
    stroke: black;
    stroke-width: 1px;
    fill: var(--background-color);

  }
`;
const StyledSvg = styled.svg`
  height: 42px;
  width: 42px;
  cursor: pointer;
  margin: auto 0;
  fill: #521d17;

`;

const ClipPath = styled.svg`
  height: 42px;
  width: 42px;
  position: absolute;
  top: -100%;
  left: -100%;
  /* transform: scale(0.21, 0.21); */
  &:hover{
    & path {
      stroke: black;
      stroke-width: 2px;
      fill: black;
    }
  }
  & path {
    stroke: black;
    stroke-width: 2px;

  }
`;


const Heading = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  height: ${props => props.openMenu ? "100vh" : props.showNav ? "70px" : "0"};
  max-height: 100vh;
  max-width: 100vw;
  top: ${props => props.showNav ? "0" : "-120px"};
  /* overflow: hidden; */
  font-weight: bold;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  font: var(--logo-font);
  transition: 0.5s all ease;
  align-items: center;
  white-space: nowrap;
  color: ${props => props.color};
  background: ${props => props.openMenu ? "var(--secondary-color)" : props.headerColor};
  overflow: hidden;
  color: #2b2b2b;
  pointer-events:none;
@media only screen and (min-width: 700px) {
  height: ${props => props.openMenu ? "100vh" : props.showNav ? "120px" : "0"};
}

  ${props => props.openMenu && `
  background: var(--secondary-color);
  `}
`;

export const Logo = styled.p`
  font: var(--logo-font);
  /* margin-left: 20px; */
  cursor: pointer;
  text-transform: uppercase;
  width: 100px;
  pointer-events:all;
  /* white-space: break-spaces; */
  @media only screen and (max-width: 700px) {
    font-size: 20px;
    font-size: 36px;
    content: "\a";
    height: fit-content;
    line-height: 38.05px;
    /* height: 80px; */
    margin: 0;

  }
`;

const LinksContainer = styled.div`
  height: 100%;
  width: 100%;
  /* font-family: var(--form-font-family); */
  font: var(--nav-font);
  font-size: 40px;
  display: flex;
  flex-flow: column nowrap;
  text-transform: uppercase;
  align-items: center;
  overflow: hidden;
  padding-top: 10px;
  pointer-events:all;

  @media only screen and (max-width: 700px) {
    
  }
  
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 20px 0;
  width: 90%;
  @media only screen and (min-width: 700px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
  }
`;

const StyledLink = styled.div`
  position: relative;
  padding: 20px 0px;
  cursor: pointer;
  font-family: var(--label-font-family);
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  line-height: 20px;
 
  @media only screen and (max-width: 700px) {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 56px;
    text-transform: none;
    line-height: 56px;
  }
  @media only screen and (min-width: 700px) {
    padding: 20px 40px;
    font-size: 20px;
  }
`;

const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;
`;

const RestyledAnchor = styled(StyledAnchor)`
  font-size: 20px;
  padding: 0 10px;
`;

const LinksList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100vh;
  height: 70%;
  margin-top: 10%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
`;

const DesktopLinksContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  pointer-events:all;
  align-items: center;
  margin-right: -40px;

`;

export default ({ color }) => {

  const history = useHistory();

  const scrollY = useRef(window.scrollY);
  const [showNav, setShowNav] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [viewOffsetTop, setViewOffsetTop] = useState(0);


  const { globalContext: { headerColor }, globalDispatch } = useContext(DataContext);

  const scrollListener = (e) => {
    setViewOffsetTop(e.target.scrollTop);
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);

  }, [])

  useEffect(() => {

    const eventHandler = (e) => {
      const currentYDist = window.scrollY;
      if (scrollY.current > currentYDist) {
        setShowNav(true);
      } else {
        if (currentYDist > 120 && !openMenu) setShowNav(false);
      }
      scrollY.current = currentYDist;
    }

    window.addEventListener("scroll", eventHandler)

    return () => window.removeEventListener("scroll", eventHandler);
  }, [])

  return (
    <Heading {...{ showNav, color, headerColor, openMenu }} >
      <HeadContainer>

        <Logo onClick={() => history.push("/")}>
          André Girão
        </Logo>
        {device() === "desktop" ? <>

          <DesktopLinksContainer>
            <StyledLink onClick={() => {
              history.push("/gallery");
            }}>
              Gallery
            </StyledLink>
            {/* <StyledLink onClick={() => {
              window.location.href = "https://www.andregirao.com/blog";
            }}>
              Blog
            </StyledLink> */}
            <StyledLink onClick={() => {
              history.push("/about");
            }}>
              About
            </StyledLink>
            <StyledLink onClick={() => {
              globalDispatch({ type: "TOGGLE_CONTACT" })
            }}>
              Contact
            </StyledLink>
          </DesktopLinksContainer>

        </> :
          <>
            <button class={"hamburger hamburger--collapse " + (openMenu && "is-active")} type="button" onClick={() => setOpenMenu(!openMenu)}>
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </>}
      </HeadContainer>
      {
        device() === "desktop" ? <></> :
          <>
            <LinksContainer>
              <LinksList>
                <StyledLink onClick={() => {
                  history.push("/gallery");
                  setOpenMenu(!openMenu);
                }}>
                  Gallery
                </StyledLink>
                {/* <StyledLink onClick={() => {
                  window.location.href = "https://www.andregirao.com/blog";
                }}>
                  Blog
                </StyledLink> */}
                <StyledLink onClick={() => {
                  history.push("/about");
                  setOpenMenu(!openMenu);
                }}>
                  About
                </StyledLink>
                <StyledLink onClick={() => {
                  globalDispatch({ type: "TOGGLE_CONTACT" })
                  setTimeout(() => {
                    // setOpenMenu(!openMenu);
                  }, 500)
                }}>
                  Contact
                </StyledLink>
              </LinksList>
              <IconsContainer>

                <RestyledAnchor href="https://www.instagram.com/giraotauffer/">
                  {/* <InstaIcon /> */}
                  Instagram
                </RestyledAnchor>
                <RestyledAnchor>
                  {" / "}
                </RestyledAnchor>
                <RestyledAnchor href="https://www.facebook.com/giraotauffer">
                  {/* <FaceIcon /> */}
                  Facebook
                </RestyledAnchor>
              </IconsContainer>
            </LinksContainer>
          </>
      }



    </Heading >
  )
}