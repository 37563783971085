import List from "./fileList";


const { FileList } = List;

export const copyToClipboard = (content) => {
  try {
    navigator.clipboard.writeText(content);
    return { message: "Copied to clipboard", status: "ok" };
  } catch (error) {
    return { message: "Failed to copy " + JSON.stringify(error), status: "critical" };
  }
}

export const imageExists = (image) => FileList.includes(image.replace(".jpg", ""));

export const getPathToImage = (image, useLightVersion) => {
  if (useLightVersion) {
    return `${image.replace(".jpg", "")}/${image.replace(".jpg", "_light.jpg")}`;
  }
  return `${image.replace(".jpg", "")}/${image}`;
}

export const deviceCheck = () => window.innerWidth > 700 ? "desktop" : "mobile";


export const parseTagName = (string) => {
  return string
    .split(" ")
    .map((word, i) => {
      if (i > 0) {
        return word.replace(word[0], word[0].toUpperCase());
      } else {
        return word
      }
    })
    .join("");
}

export const parseTagNameToTitle = (string) => {
  return string
    .split(/(?=[A-Z])/g)
    .join(" ").toLowerCase();
}