import React, { useState, useContext } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";

import Form from "../views/Form";

import { useEffect } from "react";
import { imageExists } from "../utils";
import assetsTree from "../assetsTree.json";
import InfoIcon, { InfoParagraph, InfoBox, Info } from "../components/InfoIcon";

const ViewContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media only screen and (min-width: 700px) {
  flex-flow: row nowrap;
  min-height: 100vh;

  }
`;

const ImagesContainer = styled.div`
  flex-grow: 1;  
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 70px 20px 0;
  background-color: var(--secondary-color);
  overflow: hidden;

  @media only screen and (min-width: 700px) {
  padding: 40px;
  background-color: transparent;
  width: 50%;
  
  }
`;

const FormContainer = styled.div`
  flex-grow: 1;
  background-color: var(--secondary-color);
  padding: 30px 20px;
  gap: 60px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;


  @media only screen and (min-width: 700px) {
  padding: 40px;
  width: 50%;
  max-width: 50%;
  min-height: 100%;

  }
`;

const FormBox = styled.div``;

const ReturnButton = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: auto;
  margin-left: 10px;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;


`

const StyledImage = styled.img`
  max-width: 90vw;
  height: 240px;
  object-fit: cover;
  width: 100%;
@media only screen and (min-width: 700px) {
 
  height: 375px;
  /* max-height: 400px; */
}
  ${props => props.reduced && `
    width: 80px;
    max-height: 60px;
    margin: 30px 30px 0 0 ;
  `}
`;

const PricingBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const TextColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 16px;
  width: 50%;
  
  @media only screen and (min-width: 700px) {
    width: auto;
  }
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
`;

const SecondaryTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  width: fit-content;
  `;


const Price = styled.p`
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
`;

const ContentColumn = styled.div`
  max-width: 450px;
  display: flex;
  flex-flow: column nowrap;
  gap: 60px;
`;

const ImagesBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;

  @media only screen and (min-width: 700px) {
    max-width: 520px;
  }
`;

const PriceBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  & > * {
    margin: 0;
  }
`;

const LeftArrow = () => <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.375 5.75L8.625 11.5L14.375 17.25" stroke="#2B2B2B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


const contactInitial = {
  size: "",
  frame: "",
  fullName: "",
  email: "",
  address: "",
  postalCode: "",
  extraInfo: "",
  media: "Gliceé Print"
}
const contactSchema = [
  { item: "media", type: "select", options: [], label: "Media", fieldWidth: "1/2" },
  { item: "size", type: "select", options: [], label: "Print size", if: { field: "media", value: "Gliceé Print" }, fieldWidth: "1/2" },
  { item: "gap", type: "gap", height: "50px" },
  { item: "fullName", type: "text", label: "Full name" },
  { item: "email", type: "email", label: "E-mail", required: true },
  { item: "address", type: "text", label: "Address", fieldWidth: "2/3" },
  { item: "postalCode", type: "text", label: "Postal code", fieldWidth: "1/3" },
  { item: "gap", type: "gap", height: "20px" },
  { item: "extraInfo", type: "textarea", label: "For custom requests or any aditional information" },
  { item: "gap", type: "gap", height: "50px" },
]

export default ({ }) => {

  const history = useHistory();
  const { globalContext: { allArtworks, loading, entries } } = useContext(DataContext);
  const [first, setFirst] = useState(0);

  const [formState, setFormState] = useState();

  const getId = (location) => {
    const hash = location.hash;
    const idUrl = hash ? hash.replace("#", "") : 0;
    return idUrl;
  }

  const [currentArtworkId, setCurrentArtwork] = useState(getId(history.location));

  const artwork = useMemo(() => entries.find(a => a.fields.id === currentArtworkId), [currentArtworkId, allArtworks]);

  useEffect(() => {
    if (!loading && artwork) {
      if (artwork.fields.printSizes) {
        const index = contactSchema.findIndex(el => el.item === "size");
        contactSchema[index].options = [...artwork.fields.printSizes];
      }
      const media = [];
      if (artwork.fields.availableOriginal) {
        media.push("Original");
      }
      if (artwork.fields.availablePrint) {
        media.push("Gliceé Print");
      }
      if (media.length) {
        const index = contactSchema.findIndex(el => el.item === "media");
        contactSchema[index].options = [...media];
      }
    }
  }, [loading, formState])


  return (
    <>
      <ViewContainer>
        <ImagesContainer>
          <ReturnButton onClick={() => history.push("/artwork#" + currentArtworkId)}>
            <LeftArrow></LeftArrow>
            Return</ReturnButton>
          <ImagesBox>
            {artwork &&
              <StyledImage
                alt={artwork.fields.name}
                // reduced={i !== 0}
                onClick={() => {
                  setFirst(artwork.fields.name)
                }}
                src={artwork.fields.image.fields.file.url}
                orientation={artwork.fields.orientation}
              />
            }
          </ImagesBox>
        </ImagesContainer>
        <FormContainer>

          <ContentColumn >

            <PricingBox>
              <TextColumn>
                <Title>Pricing</Title>

                <InfoBox>
                  <Info>Note that shipping bill will be added to total price</Info>
                  <InfoIcon {...{ placement: "center", title: "Shipping" }}>
                    <InfoParagraph {...{ marginTop: "0px" }}>
                      The value for shipping will depend on what are you buying and where are you buying it from.
                      <br />
                      Originals weight more than prints, and tracking also adds to the final cost. Send me your address along in the message so I'll be able to calculate the price of shipping a specific item to your place.
                    </InfoParagraph>
                  </InfoIcon>
                </InfoBox>
              </TextColumn>
              {formState && artwork && <PriceBox>
                <Price>{formState.media === "Gliceé Print" ? artwork.fields.printPrice : artwork.fields.originalPrice}kr</Price>
              </PriceBox>
              }
            </PricingBox>
            <FormBox>

              <Form
                getState={setFormState}
                initialState={contactInitial}
                schema={contactSchema}
                requireLogin={true}
                submitText="Request artwork"
                loadingText="Sending your message :)"
                actionCompleteMessage="Message sent succesfully!"
                onSubmit={(fields) => ({
                  postPath: "/emails/notify",
                  init: {
                    method: "POST", headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...fields })
                  }
                })}
              />
            </FormBox>
            <TextColumn>
              <InfoBox>
                <Info>Shipping</Info>
                <InfoIcon {...{ placement: "topRight", title: "Shipping" }}>
                  <InfoParagraph {...{ marginTop: "0px" }}>
                    The value for shipping will depend on what are you buying and where are you buying it from.
                    <br />
                    Originals weight more than prints, and tracking also adds to the final cost. Send me your address along in the message so I'll be able to calculate the price of shipping a specific item to your place.
                  </InfoParagraph>
                </InfoIcon>
              </InfoBox>
              <InfoBox>
                <Info>Refund and returns</Info>
                <InfoIcon {...{ placement: "top", title: "Return and refunds" }}>
                  <InfoParagraph {...{ marginTop: "0px" }}>
                    As a very small art producer I do not offer return or refunds.
                  </InfoParagraph>
                </InfoIcon>
              </InfoBox>
            </TextColumn>
          </ContentColumn>
        </FormContainer>
      </ViewContainer>
    </>
  )
}