import React from "react";
import styled from "styled-components";


import ContactInfo, { Title, Description, RestyledAnchor } from "../components/ContactInfo";
import Footer from "../components/Footer";
import { imageExists } from "../utils";


const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 20px;
  gap: 40px;
`;

const Imagem = styled.img`
  height: 200px;

  @media only screen and (min-width: 700px) {
    height: 300px;
  }
`;

const RestyledDescription = styled(Description)`
  text-align: start;
`;

const SpacedContactInfo = styled.div`
  margin: 20px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 60px;
    
  width: 90vw;

  background-color: var(--secondary-color);

  @media only screen and (min-width: 700px) {
  padding: 0 60px 60px 60px;
  width: auto;
  background-color: transparent;
  }


`;

const ImageAndTextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
  width: 90vw;
  margin: 30px 0;

@media only screen and (min-width: 700px) {
  margin: 100px 0;
  flex-flow: row nowrap;
}

@media only screen and (min-width: 1200px) {
  width: 70vw;
}
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 50px;
`;

export default () => {

  return (
    <Container>
      <ImageAndTextContainer>


        <Imagem src={"//images.ctfassets.net/i3402grhxdk9/29MT7F0HDyg5Gdffd4xWI0/5239f29b48d9bc3e66fbf4528cc7eb97/about.jpg?fm=jpg&fl=progressive&h=300"} alt="picture of André with a painting"></Imagem>

        <TextContainer>

          <RestyledDescription>
            Hi! I am a brazilian painter currently based in Stockholm. I work mostly with oil on canvas, but also do some works in chalk and being lately experimenting with charcoal and digital art as well.
            Main themes in my current interest are portraits and landscapes. What I really aim for is to be able to paint everything I see and to represent how I feel about it.
          </RestyledDescription>
          <RestyledDescription>
            In my website you will be able to find all of my recent works and experimentations. Here you can find a bit about each work and you can always contact me if you're interested.
            If you like my work, I suggest you subscribe to my newsletter. I'll only use it to let you know when there's new content or if there's any upcoming exhibition.
          </RestyledDescription>
        </TextContainer>
      </ImageAndTextContainer>
      <SpacedContactInfo >
        <ContactInfo />
      </SpacedContactInfo>
      <Footer />
    </Container>
  )
}