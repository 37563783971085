import React, { useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { DataContext } from "../components/DataContext";
import { animateScroll as scroll } from 'react-scroll'

import { Container, ImagesContainer, StyledImage, ImagesContainerv2, Row } from "./GalleryOverview";

import Footer from "../components/Footer";
import { parseTagName } from "../utils";

const deviceCheck = () => window.innerWidth > 700 ? "desktop" : "mobile";


const ViewTitle = styled.p`
  font-size: 26px;
  text-transform: capitalize;
  line-height: 24px;
  @media only screen and (min-width: 700px) {
    width: 90vw;
    margin: 30px auto;
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
    margin: 30px auto;
  }
`;

const DescContainer = styled.div`
  width: 90%;
  margin: 20px;

`;



export default () => {

  const history = useHistory();
  const { globalContext: { entries, tags }, globalDispatch } = useContext(DataContext);

  const currentTheme = useMemo(() => history.location.pathname.replace("/gallery/", "").replace(/_/g, " "), []);

  const currentEntries = useMemo(() => {
    return entries.filter(entry => entry.metadata.tags.find(tag => tag.sys.id === parseTagName(currentTheme)));
  }, [currentTheme, entries])

  useEffect(() => {
    globalDispatch({ type: "CHANGE_HEADER_COLOR", color: "var(--background-color)" });
  }, [])

  return (
    <Container>
      <DescContainer>
        <ViewTitle>{currentTheme}</ViewTitle>
      </DescContainer>
      <Row>

        <ImagesContainerv2>
          {currentEntries.map((entry, index) => index < currentEntries.length / 2 &&
            <>
              {console.log("the broken entry", entry)}
              {console.log("the broken entry", entry, entry.fields.image.fields.file.url)}
              <StyledImage
                onClick={() => {
                  scroll.scrollToTop({
                    duration: 1000,
                    smooth: true,
                  });
                  history.push("/artwork#" + entry.fields.id)
                }}
                src={entry.fields.image.fields.file.url}></StyledImage>
            </>
          )}
        </ImagesContainerv2>
        <ImagesContainerv2>
          {currentEntries.map((entry, index) => index >= currentEntries.length / 2 && <StyledImage
            onClick={() => {
              scroll.scrollToTop({
                duration: 1000,
                smooth: true,
              });
              history.push("/artwork#" + entry.fields.id)
            }}
            src={entry.fields.image.fields.file.url}></StyledImage>)}
        </ImagesContainerv2>
      </Row>

      <Footer />
    </Container>
  )
}