import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import Avatar from "./Avatar";


import Frame0 from "../bonfire.png/frame0.png";
import Frame1 from "../bonfire.png/frame1.png";
import Frame2 from "../bonfire.png/frame2.png";
import Frame4 from "../bonfire.png/frame4.png";


const AnimationContainer = styled.div`

  @media only screen and (max-width: 700px) {
    transform: scale(1);
  }
`;


const frame0 = new Image();
frame0.src = Frame0;
const frame1 = new Image();
frame1.src = Frame1;
const frame2 = new Image();
frame2.src = Frame2;
const frame4 = new Image();
frame4.src = Frame4;

const totalFrames = [
  frame0, frame1, frame2, frame4
]

let frames = [
  frame0,
  frame0,
  frame1,
  frame1,
  frame0,
  frame2,
  frame2,
  frame4,
  frame4,
];

const frame = new Image();
frame.src = Frame1

const CanvaContainer = styled.div`
  /* width: 600px;
  height: 400px; */
`;

export default ({ }) => {


  const time = useRef(null)
  const timeOverlay = useRef(null)
  const index = useRef(0)
  const overlayIndex = useRef(1);
  const canvas = useRef(null);
  const overlay = new Image();
  const order = useRef("a")



  function draw() {
    if (!canvas.current) return;
    var ctx = canvas.current.getContext('2d');
    overlay.src = frames[index.current + 1];
    // img.src = frames[index.current];

    canvas.current.width = frame.width;
    canvas.current.height = frame.height;
    ctx.drawImage(frames[index.current], 0, 0);
    ctx.globalAlpha = 0.2;
    if (frames[overlayIndex.current]) ctx.drawImage(frames[overlayIndex.current], 0, 0);
  }

  useEffect(() => {

    requestAnimationFrame(updateAnimation)

  }, [])

  const updateAnimation = (current) => {
    // time = new Date().getTime() - startTime;
    if (!time.current) time.current = current;
    if (!timeOverlay.current) timeOverlay.current = current;
    if (current > timeOverlay.current + 150) {

      if (order.current === "a") {
        if (overlayIndex.current < frames.length - 1) {
          overlayIndex.current = overlayIndex.current + 1;
          timeOverlay.current = current;
          draw("a");
        }
        if (index.current === frames.length - 2) {
          overlayIndex.current = overlayIndex.current - 1;
        }
      } else if (order.current === "d") {
        if (index.current > 0) {
          overlayIndex.current = overlayIndex.current - 1;
          timeOverlay.current = current;
          draw("b");
        }
        if (index.current === 1) {
          overlayIndex.current = overlayIndex.current + 1;
        }
      }
    }
    if (current > time.current + 300) {

      if (order.current === "a") {
        if (index.current < frames.length - 1) {
          time.current = current;
          index.current = index.current + 1;
          draw("a");
        }
        if (index.current === frames.length - 1) {
          order.current = "d"

        }
      } else if (order.current === "d") {
        if (index.current > 0) {
          time.current = current;
          index.current = index.current - 1;
          draw("b");
        }
        if (index.current === 0) {
          order.current = "a"
        }
      }
    }

    requestAnimationFrame(updateAnimation)
  }



  return (
    <CanvaContainer className="bonfire">
      {/* <div className="comming-soon neon-header">This will soon be an art gallery</div> */}
      <AnimationContainer>
        <Avatar />
        <canvas ref={canvas}></canvas>
      </AnimationContainer>
    </CanvaContainer>
  );
}
