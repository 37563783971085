import React, { useEffect, useReducer } from "react";


export const DataContext = React.createContext();


const initialState = {
  allArtworks: [],
  gallery: [],
  loading: true,
  isOpen: false,
  activePhoto: window.location.hash ? window.location.hash.replace("#", "") : 0,
  filters: {
    themes: [],
    sizes: [],
    colors: [],
    series: [],
  },
  themes: [],
  series: [],
  colors: [],
  sizes: [],
  series: [],
  sort: "time",
  inPlace: false,
  showFilters: false,
  displayThumbnails: true,
  activeTab: "themes",
  detailsId: null,
  headerColor: "",
  contact: false,
  popOverOpen: false,
  imagesList: [],
  entries: [],
  tags: []
}

const reducer = (state, action) => {
  const actions = {
    "LOAD_GALLERY": () => ({
      ...state,
      allArtworks: action.payload,
      gallery: action.gallery,
      themes: action.themes ? [...state.themes, ...action.themes] : state.themes,
      colors: action.colors ? [...state.colors, ...action.colors] : state.colors,
      sizes: action.sizes ? [...state.sizes, ...action.sizes] : state.sizes,
      series: action.series ? [...state.series, ...action.series] : state.series,
      displayList: action.displayList,
      loading: false,
    }),
    "SORT_GALLERY": () => ({
      ...state,
      gallery: action.payload,
    }),
    "GET_IMAGES_LIST": () => ({
      ...state,
      imagesList: action.list,
    }),
    "FILTER_GALLERY": () => ({
      ...state,
      gallery: action.payload,
      activePhoto: 0
    }),
    "OPEN_FILTERS": () => ({
      ...state,
      showFilters: action.display,
    }),
    "TOGGLE_DISPLAY_SIZE": () => ({
      ...state,
      displayThumbnails: action.display,
    }),
    "OPEN_GALLERY": () => ({
      ...state,
      isOpen: true,
    }),
    "CLICK_IMAGE": () => ({
      ...state,
      isOpen: true,
      activePhoto: action.activePhoto,
      displayThumbnails: false
    }),
    "CLOSE_GALLERY": () => ({
      ...state,
      isOpen: false,
    }),
    "GO_TO_DETAILS": () => ({
      ...state,
      detailsId: action.id,
    }),
    "SET_ACTIVE_PHOTO": () => {
      // if (window.history.pushState) {
      //   window.history.pushState(null, null, `#${action.activePhoto}`);
      // }
      return ({
        ...state,
        activePhoto: action.activePhoto,
        inPlace: false
      })
    },
    "SET_IN_PLACE": () => ({
      ...state,
      inPlace: true
    }),
    "SET_ACTIVE_TAB": () => ({
      ...state,
      activeTab: action.tab
    }),
    "SET_FILTER": () => {
      return ({
        ...state,
        displayThumbnails: false,
        filters: {
          ...state.filters,
          [action.filter]: [...state.filters[action.filter], action.value]
        }
      })
    },
    "TOGGLE_POPOVER": () => {
      return ({
        ...state,
        popOverOpen: !state.popOverOpen,
        children: action.children
      })
    },
    "SET_ONLY_FILTER": () => {

      return ({
        ...state,
        displayThumbnails: false,
        filters: {
          ...initialState.filters,
          [action.filter]: [action.value]
        }
      })
    },
    "REMOVE_FILTER": () => {
      const cleanFilters = state.filters[action.filter]
        .filter(f => f.theme !== action.value)
        .filter(f => f.size !== action.value)
        .filter(f => f.serie !== action.value);
      return ({
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: cleanFilters
        }
      })
    },
    "CLEAR_FILTERS": () => ({
      ...state,
      filters: {
        ...initialState.filters,
      }
    }),
    "CHANGE_SORT": () => ({
      ...state,
      sort: action.sort
    }),
    "CHANGE_HEADER_COLOR": () => ({
      ...state,
      headerColor: action.color
    }),
    "TOGGLE_CONTACT": () => ({
      ...state,
      contact: !state.contact
    }),
    "STORE_CSV": () => ({
      ...state,
      csv: action.csv
    }),
    "STORE_CONFIG": () => {
      const sortedGallery = state.gallery.filter(item => action.list.includes(item.id));
      return ({
        ...state,
        displayList: action.list
      })
    },
    "STORE_TAGS": () => {
      return ({
        ...state,
        tags: action.tags
      })
    },
    "STORE_ENTRIES": () => {
      return ({
        ...state,
        entries: action.entries
      })
    },
    "default": () => ({ ...state })
  };


  return actions[action.type] ? actions[action.type]() : actions["default"]();
}

export default () => {

  const [globalContext, globalDispatch] = useReducer(reducer, initialState);

  return ({ globalContext, globalDispatch });
}