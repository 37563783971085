import React from "react";
import styled from "styled-components";
import Bonfire from "./Bonfire";

import ContactInfo from "../components/ContactInfo";

const Overlay = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #22232ddb;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  opacity: 0;
  animation: showup 1s ease forwards;

  @keyframes showup {
    100%{opacity: 1}
  }
  @media only screen and (min-width: 700px) {
    /* left: auto; */
    /* padding: 0; */
    /* width: 500px;
    height: 700px; */
    /* background-color: transparent; */
  }
`;

const Content = styled.div`
  background-color: #504a5d;
  flex-grow: 1;
  width: -webkit-fill-available;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 10px;
  color: white;
  @media only screen and (min-width: 700px) {
    /* left: auto; */
    /* padding: 0; */
    width: 500px;
    height: 700px;
    flex-grow: 0;
  }
`;

const CloseIcon = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  align-self: flex-end;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-45deg);
    height: 5px;
    width: 100%;
    background-color: white;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
    height: 5px;
    width: 100%;
    background-color: white;
  }
`;

export default ({ closeOverlay, show }) => {
  const clickedCloseIcon = (e) => {
    e.stopPropagation();
    closeOverlay();
  }
  return (
    <Overlay onClick={closeOverlay} {...{ show }}>
      <Content>
        <CloseIcon onClick={clickedCloseIcon} />
        <Bonfire />
        <ContactInfo contactPopOver={true} />

      </Content>
    </Overlay>
  )
}