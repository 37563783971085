import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "./DataContext";

import EmailList from "./EmailList";
import { Logo, StyledAnchor } from "./Heading";

import EmailIcon from "../icons/EmailIcon";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";


const Container = styled.div`
  width: 100vw;
  background-color: var(--background-color);
  /* background-color: var(--footer-color);
  background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
  background-size: 800% 800%;
  -webkit-animation: BackgroundGradient 50s ease infinite;
  -moz-animation: BackgroundGradient 50s ease infinite;
  animation: BackgroundGradient 50s ease infinite;   */
  /* height: 300px; */
`;
// width: 100vw;

const StyledLogo = styled(Logo)`
  font-size: 36px;
  padding: 0;
  margin: 0;
  white-space: nowrap;
`;

const BottomRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* width: 100vw; */
  max-width: 100vw;
  font-family: var(--label-font-family);
  font-size: 14px;
  padding: 20px;
  @media only screen and (min-width: 700px) {
    width: 90vw;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props => props.right ? "flex-start" : "flex-end"};
  justify-content: space-between;
  gap: 30px;

`;

const StyledP = styled.p`
margin: 0;
align-self: flex-end;

`;
const StyledContact = styled.p`
  cursor: pointer;

`;

const IconsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 180px;
`;

const IconsAndAck = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-self: flex-end;
  margin-top: auto;
`;

export default () => {

  const history = useHistory();

  const { globalDispatch } = useContext(DataContext);

  return (
    <Container>
      <EmailList />
      <BottomRow>
        <Column right={true}>
          <StyledLogo onClick={() => history.push("/")}>André Girão</StyledLogo>
          <StyledContact onClick={() => globalDispatch({ type: "TOGGLE_CONTACT" })}>Contact</StyledContact>
          <StyledAnchor href="https://www.artmajeur.com/andre-girao">Shop</StyledAnchor>
        </Column>
        <Column>
          <IconsAndAck>

            <IconsRow>
              <a href="https://www.facebook.com/giraotauffer">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/giraotauffer/" >
                <InstagramIcon />
              </a>
              <a href="mailto: contact@andregirao.com">
                <EmailIcon />
              </a>
            </IconsRow>
            <StyledP>® All rights reserved</StyledP>
          </IconsAndAck>
        </Column>
      </BottomRow>
    </Container>
  )
}