import React, { useRef } from "react";

import { DataContext } from "../components/DataContext";


import styled from "styled-components";
import Gallery from "./Gallery";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

const ViewContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  /* height: 100vh; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
/* background: linear-gradient(0deg, rgba(80,74,93,1) 39%, rgba(96,89,112,1) 66%, rgba(135,126,154,1) 98%); */
`;


export default ({ }) => {

  const pageRef = useRef(null);
  const [isFirstDiv, setIsFirstDiv] = useState(true);

  const { globalContext: { allArtworks }, globalDispatch } = useContext(DataContext);


  useEffect(() => {
    globalDispatch({ type: "CHANGE_HEADER_COLOR", color: "" });
  }, [])

  return (
    <ViewContainer ref={pageRef} >

      <Gallery />
    </ViewContainer>
  )
}
/* <Bonfire /> */
