import React, { useRef } from "react";

import styled from "styled-components";
import Gallery from "./Gallery";
import Heading from "../components/Heading";
import { useState } from "react";
import TimedPoses from "../components/TimedPoses";
import { useEffect } from "react";
import DraggableList from "./DraggableList";

import { TimedPosesContext } from "../components/TimedPosesContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";



const ViewContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  /* height: 100vh; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
/* background: linear-gradient(0deg, rgba(80,74,93,1) 39%, rgba(96,89,112,1) 66%, rgba(135,126,154,1) 98%); */
`;

const images = [
  "Lamonte-001.jpg",
  "Lamonte-002.jpg",
  "Lamonte-003.jpg",
  "Lamonte-004.jpg",
  "Lamonte-005.jpg",
  "Lamonte-006.jpg",
  "Lamonte-007.jpg",
  "Lamonte-008.jpg",
  "Lamonte-009.jpg",
  "Lamonte-010.jpg",
]

const CountDown = styled.p`

`;

const IntervalInput = styled.input`
  font-size: 25px;
  border: none;
  width: 100px;
  font-weight: bold;
  height: fit-content;
  height: 32px;
    text-align: center;
    background: transparent;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100vw;
  align-items: center;
  justify-content: space-around;
`;

const IntervalField = styled.div`

`;


const ThumbailsContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-flow: row nowrap;
  gap: 10px;
  padding: 10px;
`;

export const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;


export default ({ }) => {

  const pageRef = useRef(null);

  const { timedPosesState: { images, interval }, timedPosesDispatch } = useContext(TimedPosesContext);

  const history = useHistory();

  const [countChange, setCountChange] = useState(new Date());

  const [countDownDate, setCountDownDate] = useState(new Date().getTime() + interval * 60000)

  const [countDown, setCountDown] = useState(0);


  useEffect(() => {
    setCountDownDate(new Date().getTime() + interval * 60000);
    setCountDown(interval + "m" + "00s");
  }, [interval, images])

  useEffect(() => {
    var now = new Date().getTime();
    const distance = countDownDate - now;

    const hours = Math.floor((distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours) {
      setCountDown("" + hours + "h" + minutes + "m " + seconds + "s ")

    } else {

      setCountDown("" + minutes + "m " + seconds + "s ")
    }

    if (distance < 0) {
      setCountDownDate(new Date().getTime() + interval * 60000);
      setCountDown(interval + "m" + "00s");
    }
  }, [countChange])

  useEffect(() => {

    const counter = setInterval(() => {

      setCountChange(new Date())
    }, 1000)
    return () => clearInterval(counter);
  }, [])


  const clickThumbnail = (index) => {
    const firstPart = images.slice(0, index);
    const lastPart = images.slice(index);

    timedPosesDispatch({ type: "REORDER_POSES", images: [...lastPart, ...firstPart] })
  }


  return (
    <ViewContainer ref={pageRef} >

      {/* <Heading {...{ pageRef: pageRef.current }} /> */}
      <div onClick={() => history.push("/timedReferences")}>cog</div>
      <TimeContainer>
        <IntervalField>

          <label>
            Interval:
        </label>
          <IntervalInput min="1" type="number" value={interval} onChange={(e) => timedPosesDispatch({ type: "INPUT", field: "interval", value: e.target.value })}></IntervalInput>
        </IntervalField>
        <CountDown>{countDown}</CountDown>
      </TimeContainer>
      {images.length ?
        <TimedPoses {...{ images: images, interval }} />
        : <></>}
      <ThumbailsContainer>
        {images.map((img, i) =>
          <Thumbnail onClick={() => clickThumbnail(i)} src={img}></Thumbnail>
        )}
      </ThumbailsContainer>

    </ViewContainer>
  )
}
