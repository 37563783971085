import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Avatar1 from "../avatar/Avatar1.png";
import Avatar2 from "../avatar/Avatar2.png";
import Avatar3 from "../avatar/Avatar3.png";
import AvatarClosingArms1 from "../avatar/AvatarClosingArms1.png";
import AvatarClosingArms2 from "../avatar/AvatarClosingArms2.png";
import AvatarClosingArms3 from "../avatar/AvatarClosingArms3.png";
import AvatarClosingArms4 from "../avatar/AvatarClosingArms4.png";
import AvatarClosingArms5 from "../avatar/AvatarClosingArms5.png";
import AvatarClosingArms6 from "../avatar/AvatarClosingArms6.png";
import AvatarOpenArms1 from "../avatar/AvatarOpenArms (1).png";
import AvatarOpenArms2 from "../avatar/AvatarOpenArms (2).png";
import AvatarOpenArms3 from "../avatar/AvatarOpenArms (3).png";

const Canvas = styled.canvas`
  position: absolute;
  transform: scale(0.6) scaleX(-1) translate(-100px, 10px);
`;


const frame1 = new Image();
frame1.src = Avatar1;
const frame2 = new Image();
frame2.src = Avatar2;
const frame3 = new Image();
frame3.src = Avatar3;
const frame4 = new Image();
frame4.src = AvatarClosingArms1;
const frame5 = new Image();
frame5.src = AvatarClosingArms2;
const frame6 = new Image();
frame6.src = AvatarClosingArms3;
const frame7 = new Image();
frame7.src = AvatarClosingArms4;
const frame8 = new Image();
frame8.src = AvatarClosingArms5;
const frame9 = new Image();
frame9.src = AvatarClosingArms6;
const frame10 = new Image();
frame10.src = AvatarOpenArms1;
const frame11 = new Image();
frame11.src = AvatarOpenArms2;
const frame12 = new Image();
frame12.src = AvatarOpenArms3;

let frames = [
  frame10,
  frame10,
  frame11,
  frame11,
  frame12,
  frame12,
  frame10,
  frame10,
  frame11,
  frame11,
  frame12,
  frame12,
  frame10,
  frame10,
  frame11,
  frame11,
  frame12,
  frame12,
  frame4,
  frame5,
  frame6,
  frame7,
  frame8,
  frame9,
  frame1,
  frame1,
  frame2,
  frame2,
  frame3,
  frame3,
  frame1,
  frame1,
  frame2,
  frame2,
  frame3,
  frame3,
  frame1,
  frame1,
  frame2,
  frame2,
  frame3,
  frame3,
];

const frame = new Image();
frame.src = Avatar1;

export default ({ }) => {

  // const [gallery, setGallery] = useState([]);

  const time = useRef(null)
  const timeOverlay = useRef(null)
  const index = useRef(0)
  const overlayIndex = useRef(1);
  const canvas = useRef(null);
  const avatar = new Image();
  const overlay = new Image();
  const order = useRef("a")



  function draw() {
    if (!canvas.current) return;
    var ctx = canvas.current.getContext('2d');
    overlay.src = frames[index.current + 1];
    // img.src = frames[index.current];

    canvas.current.width = frame.width;
    canvas.current.height = frame.height;
    ctx.drawImage(frames[index.current], 0, 0);
    // ctx.globalAlpha = 0.2;
    // if (frames[overlayIndex.current]) ctx.drawImage(frames[overlayIndex.current], 0, 0);
  }

  useEffect(() => {

    requestAnimationFrame(updateAnimation)

  }, [])

  const updateAnimation = (current) => {
    // time = new Date().getTime() - startTime;
    if (!time.current) time.current = current;
    if (!timeOverlay.current) timeOverlay.current = current;
    if (current > timeOverlay.current + 150) {

      if (order.current === "a") {
        if (overlayIndex.current < frames.length - 1) {
          overlayIndex.current = overlayIndex.current + 1;
          timeOverlay.current = current;
          draw("a");
        }
        if (index.current === frames.length - 2) {
          overlayIndex.current = overlayIndex.current - 1;
        }
      } else if (order.current === "d") {
        if (index.current > 0) {
          overlayIndex.current = overlayIndex.current - 1;
          timeOverlay.current = current;
          draw("b");
        }
        if (index.current === 1) {
          overlayIndex.current = overlayIndex.current + 1;
        }
      }
    }
    if (current > time.current + 100) {

      if (order.current === "a") {
        if (index.current < frames.length - 1) {
          time.current = current;
          index.current = index.current + 1;
          draw("a");
        }
        if (index.current === frames.length - 1) {
          order.current = "d"

        }
      } else if (order.current === "d") {
        if (index.current > 0) {
          time.current = current;
          index.current = index.current - 1;
          draw("b");
        }
        if (index.current === 0) {
          order.current = "a"
        }
      }
    }

    requestAnimationFrame(updateAnimation)
  }



  return (
    <Canvas ref={canvas}></Canvas>
  );
}