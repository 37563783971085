import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "../icons/CloseIcon";

const StyledSvg = styled.svg`
  cursor: help;
  min-width: 18px;
  margin-left: 10px;
`;

const ChildContainer = styled.div`
  position: fixed;
  z-index: 10;
  width: 0;
  overflow: hidden;
  background-color: var(--background-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s opacity ease-out, 0s width ease 0.5s;
  transform: translate(-50%, 50%);
  bottom: 50%;
  left: 50%;

  ${props => props.show && `
  transition: 0.5s opacity ease-out;
  width: 300px;
  opacity: 1;
  `}

  @media only screen and (min-width: 700px) {
    width: 50%;
  }

  /* position: absolute;
  transform: none;
  bottom: auto;
  left: auto;
  ${props => props.show && `
  width: 400px;
`} */
  @media only screen and (min-width: 700px) {
  

  /* ${props => {
    const placements = {
      center: `
      transform: translate(-50%, -50%);
      `,
      top: `
      transform: translateX(-50%);
      bottom: 0;
      `,
      topLeft: `
      right: 0;
      bottom: 0;
      `,
      bottomLeft: `
      top: 0;
      right: 0;
      `,
      left: `
      transform: translateY(-50%);
      right: 0;
      `,
      topRight: `
      bottom: 0;
      left: 0;
      `,
      bottomRight: `
      top: 0;
      left: 0;
      `,
      right: `
      transform: translateY(-50%);
      left: 0;
      `,
      bottom: `
      transform: translateX(-50%);
      top: 0;
      `
    };
    if (props.placement) {

      return placements[props.placement];
    } else {
      return placements.center;
    }
  }}*/
  } 
`;


const InfoContainer = styled.div`
  position: relative;
`;
const CloseButton = styled.svg`
  
`;

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 20px;
`;


const Overlay = styled.div`
  
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100%;
  right: 100%;
  z-index: 1;
  background-color: black;
  opacity: 0;
  transition: 0.5s opacity ease, 0s right 0.5s, 0s bottom 0.5s;

  ${props => props.show && `
    transition: 0.5s opacity ease;
    opacity: 0.4;
    right: 0;
    bottom: 0;
  `}

  /* @media only screen and (min-width: 700px) {
    display: none;
  } */
`;

export const InfoParagraph = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  flex-grow: 1;
  margin: 28px;
  ${props => props.marginTop && `
    margin-top: ${props.marginTop};
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Info = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  margin: 0;
  `;


export default ({ children, placement, title }) => {

  const [show, setShow] = useState(false);

  return <InfoContainer>
    <StyledSvg onClick={() => setShow(true)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#2B2B2B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 6H9.00694" stroke="#2B2B2B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.25 9H9V12H9.75" stroke="#2B2B2B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </StyledSvg>
    <Overlay onClick={() => setShow(false)} {...{ show }}></Overlay>
    <ChildContainer {...{ show, placement }}>
      {show && <>
        <Header >
          <InfoParagraph>
            <strong>{title}</strong>
          </InfoParagraph>

          <CloseIcon onClick={() => setShow(false)} />
        </Header>
        {children}
      </>
      }
    </ChildContainer>
  </InfoContainer>
}