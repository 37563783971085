import React, { useRef, useState } from "react";
import styled from "styled-components";

import { animateScroll as scroll } from 'react-scroll';

import InfoIcon, { InfoParagraph, InfoBox, Info } from "./InfoIcon";

import { BlackButton } from "./Buttons";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import EmailIcon from "../icons/EmailIcon";




const EmailList = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  padding: 40px 10px;
  padding-top: 200px;
  background: linear-gradient(0deg, rgba(207,235,238,1) 0%, rgba(207,235,238,1) 9%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
  /* padding-bottom: 90px; */
`;

const FormContainer = styled.div`

  display: flex;
  flex-flow: row nowrap;
  /* background: var(--footer-color); */
  clear: left;
  font-family: var(--label-font-family);
  font-size: 14px;

  

  @media only screen and (max-width: 700px) {
    flex-flow: column nowrap;
  }
  overflow: hidden;
  ${props => props.showAcknowledgment ? `
    max-height: 0
  `:
    `
  max-height: 100vh;
  `
  }
`;

const Form = styled.form`
  font-size: 14px;
  @media only screen and (min-width: 700px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1200px){
    width: 70vw;
  }
`;

export const SubmitBtn = styled.input`
  background-color: white;
  /* font: var(--logo-font); */
  width: 160px;
  border: none;
  /* color: #FF8B02; */
  font-size: 26px;
  line-height: 24px;
  padding: 10px 0;
  align-self: center;
  font-weight: normal;
  border: 1px solid #2B2B2B;
  font-size: 17px;
  box-shadow: 0px 4px 20px rgba(43, 43, 43, 0.1);

`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  text-align: center;
  max-width: 400px;
  align-self: center;
  border: 0.7px solid rgba(43, 43, 43, 0.8);
  border-radius: 6px;
  height: 48px;
  margin-top: 20px;
  font-size: 14px;

  @media only screen and (max-width: 700px) {
    width: calc(100% - 6px);
  }
`;

const Checkbox = styled.input`
  display: inline;
  width: auto;
  margin-right: 10px;
  height: 17px;
  width: 17px;
`;

const BotCatch = styled.div`
  position: absolute; 
  left: -5000px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 22px;
  align-self: center;
`;

const SignupScroll = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: fit-content;
  margin: 0 auto;
`;

const MarketingPermissionsContainer = styled.div`
  max-height: ${props => props.active ? "100vh" : "0"};
  overflow: hidden;
  transition: 1s all ease;
  display: flex;
  flex-flow: column;
  gap: 10px;
`;


const StoreOptions = styled.fieldset`
  display: flex;
  flex-flow: column;
  border: none;
  align-items: flex-start;
  width: fit-content;
  padding-left: 0;
  > label input {
    margin-left: -2px;
    padding-left: 0;
  }
`;

const ReadMore = styled.p`
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

const GdprContainer = styled.div`
  width: 400px;
  margin: 0 auto;

  @media only screen and (max-width: 700px) {
    max-width: 90vw;
  }
`;

const OptionsContainer = styled.div`
  text-align: center;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

export default ({ }) => {

  const [showMarketingPermissions, setShowMarketingPermissions] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [showAcknowledgment, setShowAcknowledgment] = useState(false);

  const [checked, setChecked] = useState(false);

  const checkbox1 = useRef(null);
  const checkbox2 = useRef(null);

  const onFocusInput = () => {
    scroll.scrollToBottom({
      duration: 1500,
      smooth: true,
    });
    setShowMarketingPermissions(true);
  }

  const onClickCheckbox = (e) => {
    if (e.target === checkbox1.current) {
      if (checkbox1.current.checked) {
        checkbox2.current.required = false;
      } else {
        checkbox2.current.required = true;
      }

    } else if (e.target === checkbox2.current) {
      if (checkbox2.current.checked) {
        checkbox1.current.required = false;
      } else {
        checkbox1.current.required = true;
      }
    }
  }

  const Acknowledgment = styled.div`
    overflow: hidden;
    
    ${props => props.showAcknowledgment ? `
    max-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    min-width: 100%;
    text-align: center;
    `:
      `
    max-width: 0;
    max-height: 0;
    `}
  `;

  const FindMeRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 170px;
  `;

  const AckTitle = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 24px;

`;

  const AckText = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 17px;

`;


  return (
    <EmailList>
      {/* <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" /> */}
      <Acknowledgment {...{ showAcknowledgment }}>
        <AckTitle>
          Thank you for subscribing!
            </AckTitle>

        <AckText>
          Please, check your e-mail for your gift. I will love to hear back from you.
            </AckText>

        <FindMeRow>
          <a href="https://www.facebook.com/giraotauffer">
            <FacebookIcon />
          </a>
          <a>
            <InstagramIcon href="https://www.instagram.com/giraotauffer/" />
          </a>
          <a href="mailto: contact@andregirao.com">
            <EmailIcon />
          </a>
        </FindMeRow>

      </Acknowledgment>
      <FormContainer id="mc_embed_signup" {...{ showAcknowledgment }}>
        <Form onSubmit={(e) => {
          setShowMarketingPermissions(false);
          setShowAcknowledgment(true);
        }}
          action="https://gmail.us1.list-manage.com/subscribe/post?u=e4dc11b9046668e9bf0f5a818&amp;id=366f76250c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
          {/* method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"> */}
          <SignupScroll id="mc_embed_signup_scroll">
            <StyledLabel for="mce-EMAIL">Subscribe to my newsletter</StyledLabel>
            <p style={{ textAlign: "center", fontSize: "17px", marginTop: "20px" }}>And receive a <strong>free</strong> printable art!</p>
            <StyledInput onFocus={onFocusInput} type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="enter your email here" required />
            <p style={{ marginTop: "12px", fontSize: "14px" }}>Don't worry, I won't spam</p>
            <MarketingPermissionsContainer active={showMarketingPermissions}>
              <GdprContainer id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
                <OptionsContainer class="content__gdpr">
                  <InfoBox>
                    <p style={{ marginBottom: "10px" }}>Which types of news you would like to receive?</p>
                    <InfoIcon {...{ placement: "topRight", title: "Regarding your data" }}>
                      <InfoParagraph {...{ marginTop: "0px" }}>

                        <p style={{ marginTop: 0 }}>You can unsubscribe at any time by clicking the link in the footer of our emails. The emails will be used solely by me to share the selected information with you. I will never sell or share your contact in any way.</p>
                        <div class="content__gdprLegal">
                          <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>
                        </div>

                      </InfoParagraph>
                    </InfoIcon>
                  </InfoBox>
                  <StoreOptions class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
                    <CheckboxLabel onClick={onClickCheckbox} class="checkbox subfield" for="gdpr_41522">
                      <Checkbox ref={checkbox1} type="checkbox" id="gdpr_41522" name="gdpr[41522]" value="Y" class="av-checkbox gdpr" required />
                      <span>New finished works</span>
                    </CheckboxLabel>
                    <CheckboxLabel onClick={onClickCheckbox} class="checkbox subfield" for="gdpr_41630">
                      <Checkbox ref={checkbox2} type="checkbox" id="gdpr_41630" name="gdpr[41630]" value="Y" class="av-checkbox gdpr" required />
                      <span>Upcoming exhibitions and events</span>
                    </CheckboxLabel>
                  </StoreOptions>
                </OptionsContainer>
              </GdprContainer>
              <BlackButton type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
            </MarketingPermissionsContainer>
            <BotCatch aria-hidden="true">
              <input type="text" name="b_e4dc11b9046668e9bf0f5a818_366f76250c" tabindex="-1" value="" />
            </BotCatch>
          </SignupScroll>
        </Form>

      </FormContainer>

    </EmailList >
  )
}