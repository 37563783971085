import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";


import { DataContext } from "../components/DataContext";

import DraggableList from "./DraggableList";

import { CSVLink, CSVDownload } from "react-csv";

import List from "../fileList";
import { imageExists } from "../utils";
import { createClient } from "contentful";

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "i3402grhxdk9",
  environment: "master",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "5SRGk4ia_iPbk4ZRbhzOH8g0OKc5iwY-3yDHw9Y5adQ"
});


const { FileList } = List;


const ViewContainer = styled.div`
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 50px;
  padding: 50px;
`;

const CardBox = styled.div`
  position: relative;
  /* padding: 15px; */
`;

const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  &.selected{
    opacity: 0.9;
    background: var(--footer-color);
    /* background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
    background-size: 800% 800%;
    -webkit-animation: BackgroundGradient 50s ease infinite;
    -moz-animation: BackgroundGradient 50s ease infinite;
    animation: BackgroundGradient 50s ease infinite; */
  }
  
`;

const Card = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

const Create = styled.div`
  width: 200px;
  height: 200px;
  border: 5px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props => props.item && `
  background-color: var(--footer-color);
  ` }
`;

const SelectMain = styled.button`
  padding: 10px;
  background: salmon;
  color: wheat;
  font-weight: bold;
  text-transform: uppercase;
  border: 5px solid salmon;
  font-size: 20px;
  cursor: pointer;


  &.active{
  color: salmon;
    background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
    background-size: 800% 800%;
    -webkit-animation: BackgroundGradient 50s ease infinite;
    -moz-animation: BackgroundGradient 50s ease infinite;
    animation: BackgroundGradient 50s ease infinite;
  }
  &:hover{
  color: salmon;
    background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
    background-size: 800% 800%;
    -webkit-animation: BackgroundGradient 50s ease infinite;
    -moz-animation: BackgroundGradient 50s ease infinite;
    animation: BackgroundGradient 50s ease infinite;
  }
`;

const Position = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ThumbailsContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-flow: row nowrap;
  gap: 10px;
  padding: 10px;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Tabs = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const Tab = styled.p`
  padding: 15px 0;
  flex-grow: 1;
  text-align: center;
  border: 5px solid transparent;
  cursor: pointer;
  ${props => props.active && `
    border: 5px solid #f7eff6;
    color: white;
    background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
    background-size: 800% 800%;
    -webkit-animation: BackgroundGradient 50s ease infinite;
    -moz-animation: BackgroundGradient 50s ease infinite;
    animation: BackgroundGradient 50s ease infinite;
  `}
`;

export default () => {

  const { globalContext: { allArtworks, displayList, csv, series, themes, entries }, globalDispatch } = useContext(DataContext);
  const [selectorActive, setSelectorActive] = useState(false);
  const [mainDisplay, setMainDisplay] = useState([]);
  const [tab, setTab] = useState("artworks");

  const [image, setImage] = useState(null)

  const history = useHistory();
  useEffect(() => {
    if (displayList) {

      setMainDisplay(displayList)
    }
  }, [displayList])

  useEffect(() => {
    fetch("/records").then(data => data.json()).then(data => globalDispatch({ type: "STORE_CSV", csv: data }))
  }, [])

  const clickOverlay = (id) => {
    if (mainDisplay.includes(id)) {
      const filtered = mainDisplay.filter(el => el !== id);
      setMainDisplay(filtered);
    } else {
      setMainDisplay([...mainDisplay, id]);
    }
  }
  console.log("the current main display", mainDisplay)

  const onClick = () => {
    const data = {
      "displayList": mainDisplay
    }
    fetch("/artworks/store/mainDisplayOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })

  }

  const onClickClean = () => {
    fetch("/artworks/store/mainDisplayOrder/clean", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })

  }

  return <ViewContainer>

    <Tabs>
      <Tab active={tab === "artworks"} onClick={() => setTab("artworks")}>artworks</Tab>
      <Tab active={tab === "themes"} onClick={() => setTab("themes")}>themes</Tab>
      <Tab active={tab === "series"} onClick={() => setTab("series")}>series</Tab>
    </Tabs>

    <SelectMain className={selectorActive && "active"} onClick={() => setSelectorActive(!selectorActive)}>Select main display</SelectMain>
    <SelectMain className={selectorActive && "active"} onClick={() => onClickClean()}>Clean main display</SelectMain>
    {selectorActive && mainDisplay.length ?
      <SelectMain onClick={onClick}>Save</SelectMain>
      : <></>}
    <DraggableList
      items={mainDisplay.map((item, index) => entries.find(ent => ent.fields.id === item) && ({
        id: "item-" + index,
        source: item,
        content: <Thumbnail
          src={entries.find(art => art.fields.id === item).fields.image.fields.file.url + "?fm=jpg&fl=progressive&w=80"}
        ></Thumbnail>
      })).filter(e => !!e)}
      setItems={setMainDisplay}
    />

    {/* {tab === "artworks" ? <>
      <SelectMain className={selectorActive && "active"} onClick={() => setSelectorActive(!selectorActive)}>Select main display</SelectMain>
      {selectorActive && mainDisplay.length ?
        <SelectMain onClick={onClick}>Save</SelectMain>
        : <></>}
      <DraggableList
        items={mainDisplay.map((item, index) => imageExists(allArtworks.find(art => art.id === item).file) || allArtworks.find(art => art.id === item).url ? ({
          id: "item-" + index,
          source: item,
          content: <Thumbnail
            src={allArtworks.find(art => art.id === item).url + "?fm=jpg&fl=progressive&w=80"}
          ></Thumbnail>
        }) : <></>)}
        setItems={setMainDisplay}
      />
    </> : <></>} */}
    <Container>
      {entries.map(entry => <CardBox>
        {selectorActive ?
          <CardOverlay className={mainDisplay.includes(entry.fields.id) && "selected"} onClick={() => clickOverlay(entry.fields.id)} >
            {(mainDisplay.indexOf(entry.fields.id) + 1) ? <Position>{(mainDisplay.indexOf(entry.fields.id) + 1)}</Position> : <></>}
          </CardOverlay>
          : <></>}
        <Card onClick={() => history.push("/store#" + entry.fields.id)} src={entry.fields.image.fields.file.url + "?fm=jpg&fl=progressive&w=200"} />
      </CardBox>)}

      {/* <CSVDownload data={csv} target="_blank" />; */}
      {tab === "artworks" ?
        csv ?
          <CSVLink data={csv} filename="artworks_records">Download all records</CSVLink>
          : <></> : <></>
      }

    </Container>
  </ViewContainer>
}