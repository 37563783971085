import React, { useEffect, useState, useRef, useReducer, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";

const Underlayer = styled.div`
  overflow: auto;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  height: 6000vh;
  width: 6000vw;
  pointer-events: none;
`;

const GalleryContainer = styled.div`
  position: absolute;
  overflow: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => `${props.backgroundColor}`};
  transition: 1s background-color ease;

`;


const Gallery = styled.div`
width: 0;
height: 0;
  
  ${props => props.detailsId &&
    `
  justify-content: center;
  align-items: center;
  `
  }

`;


export const ImageStyled = styled.picture.attrs(props => ({
  transform: props.transform
}))`
  --scale: 1;
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 80vw;
  /* max-height: 80vw; */
  object-fit: cover;
  align-self: center;
  left: 20vw;
  top: calc(60vh - 40vw);
  transform-origin: bottom center;
  z-index: ${props => props.zIndex};
  transition: 0.2s scale ease;
  
  &:hover {
    --scale: 1.2;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.15);
  }
  
  
  position: fixed;


  @media only screen and (min-width: 700px) {
    
    transition: 0.2s scale ease;
    width: 40vw;
    height: 55vw;
    left: 30vw;
    top: 10vh;

    /* height: 40vw; */
    top: calc(80vh - 45vw);
  }
  @media only screen and (min-width: 1200px) {
    left: 35vw;
    width: 30vw;
    height: 40vw;
    top: 25vh;
  }

  @media only screen and (min-width: 1500px) {
    left: 35vw;
    width: 25vw;
    height: 30vw;
    top: 25vh;
  }

  ${props => props.hide &&
    `
    display: none;
    `
  };

  ${props => props.isActive && `
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 20px 20px 0px rgba(0,0,0,0.15);
  `};

`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  margin-left: 0px;
  margin-right: 0px;
  background-color: wheat;
  z-index: ${props => props.isLoaded ? "-1" : "0"};
  opacity: ${props => props.isLoaded ? "0" : "1"};
  transition: 10s opacity ease;
`;

const displayCount = 15;

export default () => {
  const { globalContext: { allArtworks, activePhoto, entries, displayList }, globalDispatch } = useContext(DataContext);

  const history = useHistory();
  const imagesRefs = useRef([]);

  const [loadedImages, setLoadedImages] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loadedIndexes, setLoadedIndexes] = useState([]);

  const [goToDetails, setGoToDetails] = useState(false);
  const [detailsId, setDetailsId] = useState(null);
  const galleryRef = useRef(null);

  const viewWidth = window.innerWidth;
  const imgWidth = viewWidth / 3;
  const deltaX = useMemo(() => {
    if (viewWidth < 700) {
      return viewWidth / 2;
    } else if (viewWidth < 1200) {
      return (viewWidth / 4);
    } else {
      return (viewWidth / 5);
    }
  }, [viewWidth]);

  const gallery = useMemo(() => {
    let gallery = [];
    let sortedEntries = entries;

    sortedEntries.sort((a, b) => {
      const valueA = Math.random();
      const valueB = Math.random();
      return valueA < valueB ? 1 : -1;
    })
    for (let i = 1; i <= displayCount; i++) {
      gallery.push(sortedEntries[i])

    }
    return entries;
  }, [entries, displayList])



  const totalWidth = (gallery.length - 1) * deltaX;

  const imgsPositions = useMemo(() => {
    let scrollX = scrollPosition;


    const positions = gallery.map((_, i) => {
      let index = i;

      //this should be changed to it's position in the scroll
      let positionX = (i * deltaX) + scrollX;
      // const initialX = i ? i * deltaX : 1;

      if (-positionX > totalWidth / 2) {
        index = gallery.length + i;
        positionX = index * deltaX + scrollX;
      } else if (positionX > totalWidth / 2) {
        index = (i - gallery.length);
        positionX = index * deltaX + scrollX;
      }
      if (i === gallery.length - 2) {
      }

      const positionY = Math.abs(positionX) / 5;
      const opacity = Math.abs(positionX) > viewWidth ? 0 : 1;


      if (Math.abs(positionX) < imgWidth / 2) {
        globalDispatch({ type: "SET_ACTIVE_PHOTO", activePhoto: i })
      }


      let angle = (positionX) / 50;

      return ({
        rotate: angle,
        positionX,
        positionY,
        scrollX,
        active: Math.abs(positionX) < imgWidth / 2,
        opacity
      })
    })
    // 
    return positions;
  }, [gallery, scrollPosition]);


  useEffect(() => {
    if (loadedImages === allArtworks.length && galleryRef.current) {
      galleryRef.current.scrollLeft = (galleryRef.current.scrollWidth / 2) - galleryRef.current.clientWidth / 2;
      // galleryRef.current.focus();
    }
  }, [galleryRef.current, loadedImages, allArtworks])


  const sortingRules = {
    time: (a, b) => {
      const dateASplit = a.datefinished.split("/");
      const dateA = new Date(dateASplit[2], dateASplit[1] - 1, dateASplit[0])
      const dateBSplit = b.datefinished.split("/");
      const dateB = new Date(dateBSplit[2], dateBSplit[1] - 1, dateBSplit[0])
      return dateA > dateB ? -1 : 1
    }
  }


  const scrollListener = (e) => {

    const elementScrollHeight = e.target.scrollHeight;
    const elementScrollWidth = e.target.scrollWidth;

    const elementScrollLeft = e.target.scrollLeft;
    const elementScrollTop = e.target.scrollTop;

    const deltaX = Math.abs(elementScrollLeft) - (elementScrollWidth / 2);
    const deltaY = Math.abs(elementScrollTop) - (elementScrollHeight / 2);

    const total = scrollPosition - deltaY - deltaX;

    if (total > totalWidth) {
      setScrollPosition(total - totalWidth);
    } else if (- total > totalWidth) {
      setScrollPosition(total + totalWidth);
    } else {
      setScrollPosition(total);
    }

    e.target.scrollTop = elementScrollHeight / 2;
    e.target.scrollLeft = elementScrollWidth / 2;
  }

  const heightPick = (index) => {
    const positions = [
      "60"
    ]
    // const positions = [
    //   "50", "45", "40", "35", "40", "45"
    // ]

    const position = index % 6;

    return positions[position];
  }

  const scrollForward = [
    "ArrowRight", "ArrowDown", "Enter", "Space", "Tab"
  ]
  const scrollBackward = [
    "Backspace", "ArrowLeft", "ArrowUp"
  ]
  const keyDownListener = (e) => {
    if (scrollForward.includes(e.code)) {
      setScrollPosition(scrollPosition - deltaX)
    } else if (scrollBackward.includes(e.code)) {
      setScrollPosition(scrollPosition + deltaX)
    }
  }

  const imageWidth = useMemo(() => {
    if (imagesRefs.current[0]) {
      return imagesRefs.current[0].clientWidth
      // return "1400"
    }
    // return "1400"
    return "300"
  }, [loadedImages])

  const imageHeight = useMemo(() => {
    if (imagesRefs.current[0]) {
      return imagesRefs.current[0].clientHeight
      // return "1400"
    }
    // return "1400"
    return "300"
  }, [loadedImages])

  return (
    <GalleryContainer
      id="scrollable"
      ref={(node) => {
        if (node) {
          node.scrollLeft = node.scrollWidth / 2;
          node.scrollTop = (node.scrollHeight / 2);
          node.focus()
        }
      }}
      tabIndex={0}
      onScroll={scrollListener}
      onKeyDown={keyDownListener}
      {...{
        backgroundColor: allArtworks[activePhoto] && allArtworks[activePhoto].color,
      }}
    >
      <Underlayer />

      <Gallery
        className={goToDetails && "goToDetails"}
        id={"gallery-container"}
        ref={galleryRef}

        {...{
          backgroundColor: allArtworks[activePhoto] && allArtworks[activePhoto].color,
          detailsId,
          n: allArtworks.length,
          tan: Math.tan(Math.PI / allArtworks.length).toFixed(2)
        }}
      >
        {gallery && imageHeight && imageWidth && gallery.map((item, i) => <ImageStyled
          className={i === activePhoto && " navigateTo"}
          key={i + item.fields.title}
          onLoad={(e) => {
            setLoadedImages(loadedImages + 1);
          }}
          ref={(e) => {
            if (e && !imagesRefs.current.includes(e)) {
              imagesRefs.current.push(e)
            }
          }
          }
          onClick={() => {
            setGoToDetails(true);
            setDetailsId(i);
            history.push("/artwork#" + item.fields.id, {
              opacity: imgsPositions[i].opacity,
              transform: `rotate(${imgsPositions[i].rotate}deg) translate(${imgsPositions[i].positionX}px, ${imgsPositions[i].positionY}px) scale(${i === activePhoto ? 1.2 : "var(--scale)"}`
            })
          }}
          height={heightPick(i)}
          isActive={i === activePhoto}
          details={i === detailsId}
          hide={(detailsId || detailsId === 0) && i !== detailsId}
          zIndex={i === activePhoto ? "100" : i}
          style={{
            opacity: imgsPositions[i].opacity,
            transform: `rotate(${imgsPositions[i].rotate}deg) translate(${imgsPositions[i].positionX}px, ${imgsPositions[i].positionY}px)`,
          }}
        >
          <source srcset={"https:" + item.fields.image.fields.file.url + `?h=${imageHeight}&w=${imageWidth}&fm=avif&q=80`} />
          <source srcset={"https:" + item.fields.image.fields.file.url + `?h=${imageHeight}&w=${imageWidth}&fm=webp&q=80`} />
          <source srcset={"https:" + item.fields.image.fields.file.url + `?fm=jpg&fl=progressive&h=${imageHeight}&w=${imageWidth}`} />
          <img loading="lazy" style={{ objectFit: "cover", height: "100%", width: "auto" }} alt={item.fields.description} src={item.fields.image.fields.file.url + `?fm=jpg&fl=progressive&h=${imageHeight}&w=${imageWidth}`}></img>
        </ImageStyled>

        )}

      </Gallery>
    </GalleryContainer>




  );
}

