import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { DataContext } from "./DataContext";

const PopOver = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3b5a6163;
  z-index: 100;
`;


export default ({ }) => {

  const { globalContext: { popOverOpen, children }, globalDispatch } = useContext(DataContext);


  return (
    <Overlay>
      <PopOver>
        {children}
      </PopOver>
    </Overlay>
  )
}