import React, { useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { DataContext } from "../components/DataContext";

import { Container, ImageContainer, ImagesContainer, StyledImage, ImagesContainerv2, Row } from "./GalleryOverview";

import { animateScroll as scroll } from 'react-scroll'


import Footer from "../components/Footer";

import { imageExists } from "../utils";




const ViewContainer = styled.div``;

const deviceCheck = () => window.innerWidth > 700 ? "desktop" : "mobile";


const ViewTitle = styled.p`
  font-size: 26px;
  text-transform: capitalize;
  line-height: 24px;
  @media only screen and (min-width: 700px) {
    width: 90vw;
    margin: 30px auto;
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
    margin: 30px auto;
  }
`;

const RestyledImage = styled(StyledImage)`
  object-fit: cover;
  opacity: 1;

  
  @media only screen and (min-width: 700px) {
    width: 100%;
    /* min-height: 30vh; */
  }
  
  @media only screen and (min-width: 1200px) {
    /* width: 50%; */
    /* width: 23vw; */
    /* width: 500px; */
    /* height: 40vh; */
  }

  ${props => props.small && `
    width: calc(50% - 10px);
    height: 115px;
    object-fit: cover; 
  `}
  `;

const DescContainer = styled.div`
  width: 90%;
  margin: 20px;

`;



export default () => {

  const history = useHistory();
  const { globalContext: { allArtworks, entries, tags }, globalDispatch } = useContext(DataContext);


  useEffect(() => {
    globalDispatch({ type: "CHANGE_HEADER_COLOR", color: "var(--background-color)" });
  }, [])

  const currentTheme = useMemo(() => history.location.pathname.replace("/gallery/", "").replace(/_/g, " "), []);

  const themedArtworks = useMemo(() => {
    const themed = allArtworks.filter(art => art.theme === currentTheme).length ?
      allArtworks.filter(art => art.theme === currentTheme) :
      allArtworks.filter(art => art.serie === currentTheme)

    const part1 = themed.slice(0, (Math.ceil(themed.length / 2)));

    const part2 = themed.slice((Math.ceil(themed.length / 2)), themed.length);

    if (deviceCheck() === "desktop") {

      return ({ part1, part2 });
    } else {
      return themed;
    }

  }
    , [currentTheme, allArtworks]);


  const smallPicker = (index) => {
    const position = index + 1
    if (position > 1) {
      if (position % 4 === 2 || position % 4 === 3) {
        return true;
      }
    }
    return false;
  }
  return (
    <Container>
      <DescContainer>
        <ViewTitle>{currentTheme}</ViewTitle>
      </DescContainer>
      <Row>
        {deviceCheck() === "desktop" ? <>
          <ImagesContainerv2 >

            {themedArtworks && themedArtworks.part1 && themedArtworks.part1.length ?
              themedArtworks.part1.map((artwork, index) => {

                return (
                  imageExists(artwork.file) || artwork.url ?
                    <RestyledImage
                      src={artwork.url}
                      onClick={() => {
                        scroll.scrollToTop({
                          duration: 1000,
                          smooth: true,
                        });
                        history.push("/artwork#" + artwork.id)
                      }}
                    ></RestyledImage>
                    : <></>
                )
              })
              : <></>}
          </ImagesContainerv2>
          <ImagesContainerv2 >

            {themedArtworks && themedArtworks.part2 && themedArtworks.part2.length ?
              themedArtworks.part2.map((artwork, index) => {

                return (
                  imageExists(artwork.file) || artwork.url ?
                    <RestyledImage
                      src={artwork.url + `?fm=jpg&fl=progressive&w=${Math.round(window.innerWidth * 0.4)}`}
                      onClick={() => {
                        scroll.scrollToTop({
                          duration: 1000,
                          smooth: true,
                        });
                        history.push("/artwork#" + artwork.id)
                      }}
                    ></RestyledImage>
                    : <></>
                )
              })
              : <></>}
          </ImagesContainerv2>
        </> :
          <ImagesContainerv2 >

            {themedArtworks && themedArtworks.length ?
              themedArtworks.map((artwork, index) => {

                return (
                  imageExists(artwork.file) || artwork.url ?
                    <RestyledImage
                      src={artwork.url + `?fm=jpg&fl=progressive&w=${Math.round(window.innerWidth * 0.4)}`}
                      small={smallPicker(index)}
                      onClick={() => {
                        scroll.scrollToTop({
                          duration: 1000,
                          smooth: true,
                        });
                        history.push("/artwork#" + artwork.id)
                      }}
                    ></RestyledImage>
                    : <></>
                )
              })
              : <></>}

          </ImagesContainerv2>}
      </Row>
      <Footer />
    </Container>
  )
}