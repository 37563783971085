import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { InternalServerError } from "http-errors";
import { useMemo } from "react";

const images = [
  "Lamonte-001.jpg",
  "Lamonte-002.jpg",
  "Lamonte-003.jpg",
  "Lamonte-004.jpg",
  "Lamonte-005.jpg",
  "Lamonte-006.jpg",
  "Lamonte-007.jpg",
  "Lamonte-008.jpg",
  "Lamonte-009.jpg",
  "Lamonte-010.jpg",
]

const RefImage = styled.img`
  width: 600px;
  height: auto;
`;

export default ({ interval = 5, images }) => {
  const [image, setImage] = useState(images[0]);
  const [imageChange, setImageChange] = useState(new Date());


  const intervalMilis = useMemo(() => interval * 60 * 1000, [interval])

  useEffect(() => {
    const intervalSet = setInterval(() => setImageChange(new Date()), intervalMilis);

    return () => clearInterval(intervalSet);

  }, [interval, images])

  useEffect(() => { setImage(images[0]) }, [images])

  useEffect(() => {
    const currentImageIndex = images.indexOf(image);
    let nextImageIndex;

    if (images.length > currentImageIndex + 1) {
      nextImageIndex = currentImageIndex + 1;
    } else {
      nextImageIndex = 0;
    }
    setImage(images[nextImageIndex]);
  }, [imageChange])

  return <RefImage src={image}></RefImage>

}