import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useMemo } from "react";
import { get } from "react-scroll/modules/mixins/scroller";

const StyledSvg = styled.svg`
  height: 42px;
  width: 42px;
  cursor: pointer;
  margin: auto 0;
  fill: #521d17;

`;


export const TwitterIcon = ({ onClick }) => {

  return (
    <StyledSvg onClick={onClick} xmlns="http://www.w3.org/2000/svg"
      height="512pt" viewBox="0 0 512 512" width="512pt">
      <path d="m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-102.1875 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0" />
    </StyledSvg>
  )
};

export const FaceIcon = ({ onClick }) => {

  return (
    <StyledSvg onClick={onClick} xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
    </StyledSvg>
  )
};

export default ({ url, title, description, file }) => {

  const parsedURL = useMemo(() => {
    const params = new URLSearchParams();
    params.append("u", url);
    return params.toString();
  }, [url]);

  return (<div class="fb-share-button" data-href={url} data-layout="button_count" data-size="large">
    <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?${parsedURL}&amp;src=sdkpreparse`} class="fb-xfbml-parse-ignore">
      <Helmet>
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={window.location.origin + "/static/media/" + file} />
      </Helmet>
      <FaceIcon />
    </a>
  </div>)
}