import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";
import Draggable from 'react-draggable';

import Heading from "../components/Heading";
import Form from "./Form";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  padding: 50px;
  display: flex;
  flex-flow: row nowrap;
  background-color: ${props => props.backgroundColor};
`;

const Preview = styled.img`
  height: 400px;
  width: auto;
`;

const CropPreview = styled.img`
position: absolute;
  height: 400px;
  width: auto;
  clip-path: ${props => props.inset ? `inset(${props.inset})` : "none"};
  top: ${props => props.top ? props.top : 0};
  left: ${props => props.left ? props.left : 0};
  width: ${props => props.width ? `${props.width}px` : "auto"};
  height: ${props => props.height ? `${props.height}px` : "400px"};
  /* width: ${props => props.width ? `calc(100% + ${props.width}px)` : "auto"}; */
  /* height: ${props => props.height ? `calc(100% + 100% - ${props.height}px)` : "100%"}; */
  object-fit: cover;
  /* object-position: ${props => props.objectPosition}; */
`;

const PreviewContainer = styled.div`
  height: 400px;
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-top: 100px;
`;

const ColorPreview = styled.div`
  position: absolute;
  margin: -40px;
  background-color: ${props => props.backgroundColor};
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  z-index: -1;
`;

const Handle = styled.div`
  width: ${props => props.vertical ? "10px" : "100%"};
  height: ${props => props.vertical ? "100%" : "10px"};
  background-color: #2b402b;
  position: absolute;

  ${props => props.top ? `
    top: 0;
  `:
    `
    bottom: 0;
  `}
  ${props => props.left ? `
    left: 0;
  `:
    `
    right: 0;
  `
  }

`;

const SideBar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
`;

const CropBox = styled.div`
position: relative;
  width: auto;
  height: 400px;
  width: ${props => props.width ? props.width : "auto"};
  height: ${props => props.height ? props.height : "400px"};
`;

const ReturnButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`
const ImageSelectorContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;


export default ({ }) => {

  const { globalContext: { allArtworks, gallery, themes, series, loading, ...rest } } = useContext(DataContext);
  const [formState, setFormState] = useState();
  const [detail, setDetail] = useState(null);
  const [selectionX, setSelectionX] = useState(0);
  const [selectionY, setSelectionY] = useState(0);
  const [selectionXOpposite, setSelectionXOpposite] = useState(0);
  const [selectionYOpposite, setSelectionYOpposite] = useState(0);
  const [fileList, setFileList] = useState([])
  const history = useHistory();
  const [selected, setSelected] = useState(undefined);
  const image = useRef(null);

  // useEffect(() => {
  //   const id = history.location.hash.replace("#", "");
  //   if (history.location.hash.replace("#", "") >= 0 && fileList.length && allArtworks.length) {
  //     const img = allArtworks.find(el => Number(el.id) === Number(id))
  //     if (img) {
  //       setSelected(img.file)
  //     }
  //   }
  // }, [fileList, gallery])

  const [orientation, setOrientation] = useState("");


  useEffect(() => {
    fetch("/images/")
      .then(data => data.json())
      .then(data => {
        return data;
      })
      .then(data => setFileList(data.items))

  }, [])

  const artworksSchema = [
    { item: "title", type: "text" },
    { item: "description", type: "textarea" },
    { item: "alt", type: "text" },
    { item: "theme", type: "select", options: [...themes.map(el => el.theme)] },
    { item: "serie", type: "select", options: ["", ...series.map(el => el.serie)] },
    { item: "color", type: "color-pick" },
    { item: "materials", type: "text" },
    { item: "dimensions", type: "text" },
    {
      item: "file", type: "select", options: fileList.map(f => {
        if (f) {
          return f.fields.file.fileName
        }
      })
    },
    { item: "location", type: "text" },
    { item: "size", type: "select", options: ["xlarge", "large", "medium", "small", "xsmall"] },
    { item: "datefinished", type: "text" },
    { item: "available_original", type: "boolean" },
    { item: "available_print", type: "boolean" },
    { item: "print_sizes", type: "multiple", options: ["A4(29,7x21cm", "A3(42x29,7cm)"] },
    { item: "original_price", type: "number" },
    { item: "print_price", type: "number" }
  ];

  const artworksInitial = useMemo(() => {

    const id = history.location.hash.replace("#", "");
    const defaultState = {
      title: "",
      description: "",
      alt: "",
      theme: "",
      color: "",
      location: "",
      size: "",
      file: selected,
      serie: "",
      price: "",
      datefinished: "",
      print_sizes: []
    };
    if (allArtworks.length) {
      const item = allArtworks.find(el => Number(el.id) === Number(id));

      if (item) {
        Object.keys(item).forEach(key => {
          if (item[key] !== null) {
            defaultState[key] = item[key];
          }
          if (defaultState[key] === null) {
            defaultState[key] = ""
          }
        });
        // if (item.file && fileList.length) {
        //   const fileInList = fileList.find(img => img.fields.file.fileName === item.file);
        //   // setSelected(item.file);
        // }
      }
    }

    return defaultState
  })

  const selectImageSchema = [
    {
      item: "file", type: "select", options: fileList.map(f => {
        if (f) {
          return f.fields.file.fileName
        }
      }).sort((a, b) => a < b ? -1 : 1)
    }
  ];


  const selectImageInitial = {
    file: (() => {
      if (fileList.length) {
        if (formState && formState.file) {
          const foundInList = fileList.find(el => el.fields.file.fileName.includes(formState.file));
          if (foundInList && foundInList.fields?.file?.fileName) {
            return foundInList.fields.file.fileName
          } else {
            return fileList[0].fields.file.fileName
          }
        } else {
          return fileList[0].fields.file.fileName
        }
      } else {
        return ""
      }
    })(),
  };

  const imageUrl = useMemo(() => {
    if (fileList.length && selected) {
      const item = fileList.find(file => file.fields.file.fileName === selected);
      if (item) {

        return item.fields.file.url;
      }
    }
  }, [fileList, selected]);


  useEffect(() => {
    if (!loading) {
      if (themes && themes.length) {
        const index = artworksSchema.findIndex(el => el.item === "theme");
        console.log("there are themes", themes)
        artworksSchema[index].options = [...themes.map(el => el.theme)];
      }
      if (series && series.length) {
        const index = artworksSchema.findIndex(el => el.item === "serie");
        artworksSchema[index].options = ["", ...series.map(el => el.serie)];
      }
    }
  }, [themes]);

  const getUrl = () => {
    const img = fileList.find(({ fields }) => fields.file.fileName === formState.file);
    if (img) {
      return img.fields.file.url;
    }
  };


  const preview = useMemo(() => {
    return `${getUrl()}?fm=jpg&fl=progressive`;
  }, [formState]);


  useEffect(() => {
    if (gallery && gallery.length) {
      const id = window.location.hash.replace("#", "");
      let item = "";
      item = gallery.find(i => i.id === Number(id));
      if (item && item.detail) {

        setDetail(item.detail);
      }
    }
  }, [gallery])

  useEffect(() => {
    if (preview) {
      const img = new Image();
      img.src = preview;
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (width >= height) {
        setOrientation("landscape");
      } else {
        setOrientation("portrait");
      }
    }

  }, [preview])


  const handleDrag = (e, data) => {
    setSelectionY(selectionY + data.deltaY);
    setSelectionX(selectionX + data.deltaX);
  }

  const handleDragOpposite = (e, data) => {
    setSelectionYOpposite(selectionYOpposite + data.deltaY);
    setSelectionXOpposite(selectionXOpposite + data.deltaX);
  }

  const clickHandler = (e) => {
    if (image.current) {
      const imageXOffset = image.current.offsetLeft;
      const imageYOffset = image.current.offsetTop;
      const imageWidth = image.current.clientWidth;
      const imageHeight = image.current.clientHeight;
      const eventY = e.pageY;
      const eventX = e.pageX;
      const clickX = eventX - imageXOffset;
      const clickY = eventY - imageYOffset;
      if (selectionX || selectionY) {
        // add two extra dragabbles to close in the rectangle
        const selectionWidth = selectionX;
      }
    }
  }

  const cropDimensions = useMemo(() => {
    const dimensions = {}
    if (image.current) {

      const imageWidth = image.current.clientWidth;
      if (selectionX || selectionXOpposite) {


        // if (!selectionXOpposite) {
        //   dimensions.width = imageWidth - selectionX;
        // } else {
        //   dimensions.width = imageWidth - (-selectionXOpposite + selectionX);
        // }
        const selectionXPerc = Math.abs(selectionX) / imageWidth;
        const selectionXOppositePerc = Math.abs(selectionXOpposite) / imageWidth;
        dimensions.width = imageWidth * (1 + selectionXPerc + selectionXOppositePerc);
      } else {
        dimensions.width = imageWidth;
      }

      const imageHeight = image.current.clientHeight;
      if (selectionY || selectionYOpposite) {
        // if (!selectionYOpposite) {
        //   dimensions.height = imageHeight + selectionY;
        // } else {
        //   dimensions.height = imageHeight - (selectionYOpposite - selectionY);
        // }
        const selectionYPerc = Math.abs(selectionY) / imageHeight;
        const selectionYOppositePerc = Math.abs(selectionYOpposite) / imageHeight;
        dimensions.height = imageHeight * (1 + selectionYPerc + selectionYOppositePerc);
      }
      dimensions.objectPosition = `-${100 * selectionX / imageWidth}% -${100 * selectionYOpposite / imageHeight}px`
      dimensions.left = `-${100 * selectionX / imageWidth}%`
      dimensions.top = `-${100 * selectionYOpposite / imageHeight}%`
      // dimensions.inset = `(${100 * Math.abs(selectionYOpposite) / imageHeight}% ${100 * Math.abs(selectionXOpposite) / imageWidth}% ${100 * Math.abs(selectionY) / imageHeight}% ${100 * Math.abs(selectionX) / imageWidth}%)`
      setDetail(`${100 * Math.abs(selectionYOpposite) / imageHeight}% ${100 * Math.abs(selectionXOpposite) / imageWidth}% ${100 * Math.abs(selectionY) / imageHeight}% ${100 * Math.abs(selectionX) / imageWidth}%`);
      return dimensions;
    }
  }, [selectionX, selectionXOpposite, selectionY, selectionYOpposite]);
  return ((selected || artworksInitial.file) ? fileList.length &&
    <Container {...{ backgroundColor: `${formState ? formState.color : "transparent"}` }}>
      <ReturnButton onClick={() => history.push("/updatestore")} >Return</ReturnButton>
      <Form
        getState={setFormState}
        initialState={artworksInitial}
        schema={artworksSchema}
        requireLogin={true}
        onSubmit={(fields) => {
          const url = getUrl();
          return ({
            postPath: "/artworks/store",
            init: {
              method: "POST", headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ ...fields, detail, orientation, url })
            }
          })
        }}
      />
      <SideBar>

        <PreviewContainer ref={image}>
          {preview ?
            <>
              <Preview onClick={clickHandler} className="preview" src={preview} />
              <Draggable
                axis="x"
                handle=".handle"
                position={null}
                scale={1}
                bounds="parent"
                // onStart={this.handleStart}
                onDrag={handleDragOpposite}
              // onStop={this.handleStop}
              >
                <Handle vertical={true} className="handle"></Handle>
              </Draggable>
              <Draggable
                axis="x"
                handle=".handle"
                position={null}
                scale={1}
                bounds="parent"
                // onStart={this.handleStart}
                onDrag={handleDrag}
              // onStop={this.handleStop}
              >
                <Handle left={true} vertical={true} className="handle"></Handle>
              </Draggable>
              <Draggable
                axis="y"
                handle=".handle"
                position={null}
                scale={1}
                bounds="parent"
                // onStart={this.handleStart}
                onDrag={handleDrag}
              // onStop={this.handleStop}
              >
                <Handle className="handle"></Handle>
              </Draggable>
              <Draggable
                axis="y"
                handle=".handle"
                position={null}
                scale={1}
                bounds="parent"
                // onStart={this.handleStart}
                onDrag={handleDragOpposite}
              // onStop={this.handleStop}
              >
                <Handle top={"0px"} className="handle"></Handle>
              </Draggable>
            </>


            : <></>
          }
        </PreviewContainer>
        {image.current &&
          <>
            <CropBox >
              <CropPreview onClick={clickHandler} className="preview" src={preview} inset={detail} {...{ ...cropDimensions }} />
            </CropBox>
          </>
        }
      </SideBar>
    </Container>
    :
    fileList.length && <Container>
      <ReturnButton onClick={() => history.push("/updatestore")} >Return</ReturnButton>
      <ImageSelectorContainer>
        <CropBox >
          <CropPreview onClick={clickHandler} className="preview" src={preview} inset={detail} {...{ ...cropDimensions }} />
        </CropBox>
        <Form
          getState={setFormState}
          initialState={selectImageInitial}
          schema={selectImageSchema}
          requireLogin={true}
          customAction={(state) => {
            setSelected(state.file)
          }}
        />
      </ImageSelectorContainer>
    </Container>
  )
}