import React, { useRef, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "./DataContext";

import ShareIcon from "../components/ShareIcon";
import { copyToClipboard } from "../utils";

import FacebookShare, { TwitterIcon } from "./FacebookShare";

import ReactMarkdown from "react-markdown";




const DescriptionExplanation = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  gap: 10px;
  line-height: 23px;
  font-weight: normal;
  /* width: 300px;  */
  /* margin: 0 auto; */
  font-size: 14px;
  height: 100%;
  width: 50%;

  @media only screen and (max-width: 700px) {
  gap: 20px;
  width: 100%;
  }
`;

const detailsSizePicker = (size) => {
  const sizes = {
    small: "0.7rem",
    default: "1rem"
  }

  return sizes[size] ? sizes[size] : sizes.default;
}

const DescriptionField = styled.p`
  ${props => props.title && `
    font-size: 20px;
    font-family: var(--label-font-family);
    font-weight: 400;

  `}
  `;

const Field = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  font-size: ${props => props.size && detailsSizePicker(props.size)};
  & p{
  margin: 0;
  }
  /* font-size: 15px; */
  `;
// TODO: do a thing

const FieldName = styled.div`
  font-weight: bold;
  letter-spacing: 1px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  max-width: 100%;

  @media only screen and (max-width: 700px) {
    padding-left: 0;
    flex-flow: column;
    width: calc(100vw - 40px);
    margin: 0 auto;
  }
`;

const FilterSerie = styled.strong`
  padding: 0 4px;
  font-weight: bold;
  cursor: pointer;
`;

const MaterialAndSize = styled.div``;

const Head = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
`;

const ShareBox = styled.div`
  padding: 20px;
  background-color: var(--background-color);
`;

const CurrentHref = styled.p`
  position: relative;
  background-color: #e0dfdf9e;
  font-size: 16px;
  color: #444442;
  padding: 10px;
  max-width: 300px;
  overflow: hidden;
  :hover{
    color: black;
    cursor: pointer;
  }
  
  &.copied {
    content: "copied";
  }
  `;

const CopiedMessage = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: -5px;
  opacity: 0;
  background-color: #adf5ac;
  display: flex;
  justify-content: center;
  align-items: center;
  color: transparent;
  transition: 1s opacity ease-out, 0.5s color ease 1s;


&.copied{
  opacity: 1;
  color: black;
}
`;

export const BlackButton = styled.button`
  background-color: #2b2b2b;
  color: rgba(251, 251, 251, 0.9);
  width: 100%;
  height: 52px;
  border-radius: 6px;
  line-height: 30px;
  font-size: 19px;
  font-family: 'Lato';
  cursor: pointer;
  `;

const PriceTag = styled.strong`
  font-size: 20px;
  `;

const ShareButtonsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
`;

const PopOverHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

`;

const ThankYouMessage = styled.p`
  font-size: 20px;
`;

const CloseButton = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  

  ::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: black;
    transform: translate(50%, 0) rotate(45deg)
  }
  
  ::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: black;
    transform: translate(50%, 0) rotate(-45deg)
  }
`;

const PurchaseBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 250px;
  align-self: flex-end;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

const PriceLine = styled.p`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const ButtonSubTitle = styled.p`
  font-weight: 300;
  margin: 0 5px;
`;


export default ({ artwork }) => {
  const { globalContext: { allArtworks }, globalDispatch } = useContext(DataContext);

  const href = useRef(null);

  const history = useHistory();

  const twitterUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.append("url", window.location.href);
    params.append("text", artwork.fields.title);
    params.append("hashtags", "andregirao, oilpainting");
    return params.toString();

  })

  const availabilityMessage = () => {
    const print = artwork.fields.availablePrint;
    const original = artwork.fields.availableOriginal;
    return print && original ? "Available for prints & original" : print && !original ? "Available for prints" : !print && original ? "Original is available for purchase" : "";
  }

  return (
    <InfoContainer onClick={e => e.stopPropagation()}>

        <>< DescriptionExplanation >
          <Head>

            <Field>
              <DescriptionField {...{ title: true }}>
                {artwork.fields.title}
              </DescriptionField>
              {artwork.fields.datefinished ? new Date(artwork.fields.datefinished).getFullYear() : <></>}
            </Field>
            <ShareIcon onClick={() => {
              globalDispatch({
                type: "TOGGLE_POPOVER",
                children: <ShareBox>
                  <PopOverHeader>

                    <ThankYouMessage>

                      Thank you for sharing my art!
                    </ThankYouMessage>
                    <CloseButton onClick={() => globalDispatch({ type: "TOGGLE_POPOVER" })} />

                  </PopOverHeader>
                  <CurrentHref onClick={() => {
                    copyToClipboard(window.location.href)
                    if (href.current) {
                      href.current.classList.add("copied");
                      href.current.innerText = "Link copied successfully!"
                      setTimeout(() => {
                        href.current.classList.remove("copied");
                        href.current.innerText = window.location.href;
                      }, 5000)
                    }
                  }}>
                    <CopiedMessage ref={href} />
                    {window.location.href}
                  </CurrentHref>
                  <ShareButtonsRow>

                    <FacebookShare
                      url={window.location.href.replace("localhost:3000", "www.andregirao.com")}
                      title={artwork.fields.title}
                      description={artwork.fields.description}
                      file={artwork.fields.file}
                    />
                    <a class="twitter-share-button"
                      href={`https://twitter.com/intent/tweet?${twitterUrl}`}>
                      <TwitterIcon /></a>
                  </ShareButtonsRow>
                </ShareBox>
              })

            }} />

          </Head>
          <MaterialAndSize>

            {artwork.fields.materials &&
              <Field>
                {artwork.fields.materials}
                {artwork.fields.dimensions && " | " + artwork.fields.dimensions}
              </Field>
            }
          </MaterialAndSize>
          {artwork.fields.description &&
            <Field>
              <DescriptionField>
                <ReactMarkdown children={`${artwork.fields.description}`} ></ReactMarkdown>
              </DescriptionField>
            </Field>
          }
          {(artwork.fields.availablePrint || artwork.fields.availableOriginal) &&
            <Field>
              <DescriptionField>

                {availabilityMessage()}
              </DescriptionField>
            </Field>
          }

        </DescriptionExplanation>
          {(artwork.fields.availablePrint || artwork.fields.availableOriginal) &&
            <DescriptionExplanation>
              <PurchaseBox>

                <PriceLine>Price starting at <PriceTag>{artwork.fields.printPrice}kr</PriceTag></PriceLine>
                <BlackButton onClick={() => history.push("/order#" + artwork.fields.id)}>Contact for order</BlackButton>
                <ButtonSubTitle>Please contact me for orders.</ButtonSubTitle>
              </PurchaseBox>
            </DescriptionExplanation>
          }
        </>

    </InfoContainer >
  )
}