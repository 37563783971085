import React, { useEffect, useReducer, useRef, useContext, useState, useMemo } from "react";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";
import Cookies from 'universal-cookie';
import { ChromePicker, BlockPicker } from 'react-color';
import Toggle from 'react-toggle';
import "./React-toggle.css"

import { BlackButton } from "../components/Buttons";



const cookies = new Cookies();

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: space-between;
  gap: 10px;
`;

const FormLoadingOverlay = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 1;
  background-color: var(--secondary-color);

  font-family: 'VT323', Fallback, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const widthPicker = {
  "1/2": "45%",
  "1/3": "30%",
  "2/3": "60%"
}

const InputField = styled.div`
  /* margin: 10px 20px; */
  line-height: 30px;
  font-size: 15px;
  display: flex;
  flex-flow: column;
  text-align: start;
  width: 100%;
  ${props => props.fieldWidth ? `width: ${widthPicker[props.fieldWidth]}` : "width: 100%"};
  input, select, textarea{
    border: 0.7px solid rgba(43, 43, 43, 0.8);
    box-sizing: border-box;
    border-radius: 6px;
    background-color: transparent;
    text-indent: 10px;
  }
  input, select{
    height: 34px;
  }
  textarea{
    height: 152px;
    padding-top: 5px;
  }
  &.focus input{
    border: 1px solid red;
    border-radius: 5px;
  }
`;

const TextArea = styled.textarea`
  height: 100px;
`;

const Gap = styled.div`
  height:${props => props.height ? props.height : "50px"};
  width: 100%;
`;

const StyledSelect = styled.select`
  border: none;
`;


const reducer = (state, action) => {
  const actions = {
    "BUILD_FORM": () => ({
      ...state,
      create: { ...action.create },
      inputSchema: action.inputSchema,
      operationsSchema: action.operationsSchema,
      invalidFields: action.invalidFields,
      tiedFields: action.tiedFields,
      update: action.update,
      built: true
    }),
    "FILL_FORM": () => {
      if (action.item && action.item.print_sizes === null) {
        delete action.item.print_sizes;
      }

      return ({
        ...state,
        ...action.item,
        id: action.id
      })
    },
    "FORM_INPUT": () => {
      return {
        ...state,
        [action.field]: action.value
      }
    },
    "FORM_INPUT_BOOLEAN": () => {
      return {
        ...state,
        [action.field]: !state[action.field]
      }
    },
    "FORM_INPUT_BOOLEAN_MULTI": () => {
      const index = state[action.field].indexOf(action.option);
      if (index >= 0) {
        const array = state[action.field];
        array.splice(index, 1);
        return {
          ...state,
          [action.field]: [...array]
        }
      }
      return {
        ...state,
        [action.field]: [
          ...state[action.field],
          action.option
        ]
      }
    },
    "ARRAY_INPUT": () => {
      const { field, value } = action;
      const existing = state.create[field].tags.find(tag =>
        tag.id === value.id
      );
      if (!existing) {

        return {
          ...state,
          create: {
            ...state.create,
            [field]: {
              input: "",
              tags: [...state.create[field].tags, value]
            }
          }
        }
      }
      return { ...state };
    },
    "ARRAY_DELETE": () => {
      const { field, value } = action;
      return {
        ...state,
        create: {
          ...state.create,
          [field]: {
            ...state.create[field],
            tags: [...value]
          }
        }
      }
    },
    "SET_IMAGE": () => {
      return {
        ...state,
        image: action.image
      }
    },
    "IMAGE_PREVIEW": () => {
      return {
        ...state,
        preview: action.preview
      }
    },

    "LOADING": () => {
      return {
        ...state,
        loading: true
      }
    },
    "LOADED": () => {
      return {
        ...state,
        loading: false
      }
    },
    "CLEAN_FORM": () => {
      return {
        ...state,
        create: {
          ...action.create,
        },
        update: false,
      }
    },
    "ALLOW_PUT": () => {
      return {
        ...state,
        allowPut: true
      };
    },
    "BLOCK_PUT": () => {
      return {
        ...state,
        allowPut: false
      };
    },
    "OPEN_SECTION": () => {

      const inputSchema = state.inputSchema.map((field, i) => {
        if (field.type === "section") {
          if (action.payload.includes(i)) {
            field.active = true;
            return field;
          }
          field.active = false;
          return field;
        }
        return field;
      })
      return {
        ...state,
        inputSchema
      };
    },
    "VALIDATION_ERRORS": () => {
      return {
        ...state,
        error: action.errors
      };
    },
    "default": () => ({ ...state })
  }

  return actions[action.type] ? actions[action.type]() : actions["default"]();
}



export default ({ schema, doFetch, initialState = {}, customAction, onSubmit, getState, submitText = "submit", SubmitBtnComponent, StyledInput, loadingText = "Storing the stuff!" }) => {


  const { globalContext: { allArtworks, gallery, series, themes }, globalDispatch } = useContext(DataContext);

  const [emptyRequired, setEmptyRequired] = useState([]);

  useEffect(() => {
    if (doFetch) {

      fetch("/artworks/").then(data => data.json()).then(response => {
        const { artworks, colors, themes, locations, files, sizes, series } = response

        globalDispatch({ type: "LOAD_GALLERY", payload: artworks, themes, locations, sizes, colors, series })
      })
    }

  }, []);

  const initial = useMemo(() => {
    const state = {
      loading: false
    }

    schema.forEach(el => {
      if (el.item !== "gap") {
        state[el.item] = ""
      }
    });
    return { ...state, ...initialState };
  }, [schema]);

  const [formState, formDispatch] = useReducer(reducer, initial);


  useEffect(() => {
    if (getState) {
      getState(formState);
    }
  }, [formState]);

  useEffect(() => {
    if (allArtworks && allArtworks.length) {
      let id = window.location.hash.replace("#", "");
      let item = "";
      if (window.location.pathname.indexOf("series") >= 0) {
        item = series.find(sr => Number(sr.id) === Number(id));
      } else if (window.location.pathname.indexOf("themes") >= 0) {
        item = themes.find(i => Number(i.id) === Number(id));
      } else {
        item = allArtworks.find(i => Number(i.id) === Number(id));
      }
      if (item) {

        Object.keys(item).forEach(key => {
          if (item[key] === null) {
            item[key] = ""
          }
        })
      }
      formDispatch({ type: "FILL_FORM", item, id })
    }
  }, [allArtworks])

  const checkRequiredFields = () => {
    const required = schema.filter(el => el.required);
    const empty = required.filter(field => !formState[field.item]);
    setEmptyRequired(empty.map(el => el.item));
    if (empty.length) return false;
    return true;
  };

  const onClickSubmit = async (e) => {
    e.preventDefault();
    if (onSubmit) {

      if (!checkRequiredFields()) return;
      formDispatch({ type: "LOADING" });
      const filledFields = {}
      Object.keys(formState).map(field => formState[field] ? filledFields[field] = formState[field] : null);
      const { postPath, init } = onSubmit(filledFields);

      await fetch(postPath, init);
      formDispatch({ type: "LOADED" })
    } else if (customAction) {
      customAction(formState);
    }
  }


  const checkCondition = (field) => formState[field.if.field] === field.if.value;

  return (
    <Form onSubmit={onClickSubmit} method="POST" enctype="multipart/form-data">
      {formState.loading ? <FormLoadingOverlay>{loadingText}</FormLoadingOverlay> : <></>}
      {schema.map((field, i) =>
        field.if && !checkCondition(field) ? <> </> :
          field.type === "textarea" ?
            <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
              <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
              <TextArea type={field.type} id={field.item} name={field.item} value={formState[field.item]} onChange={(e) => formDispatch({ type: "FORM_INPUT", field: field.item, value: e.target.value })} />
            </InputField>
            :
            field.type === "select" ?
              field.options.length ? <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                <StyledSelect id={field.item} name={field.item} onChange={(e) => formDispatch({ type: "FORM_INPUT", field: field.item, value: e.target.value })}>
                  {field.options && field.options.map(opt => <option value={opt} selected={formState[field.item] === opt ? "selected" : ""}>{opt}</option>)}
                </StyledSelect>
              </InputField> : <></>
              : field.type === "color-pick" ?
                <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                  <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                  <ChromePicker
                    color={formState.color}
                    onChange={(e) => {
                      if (e) {
                        formDispatch({ type: "FORM_INPUT", field: field.item, value: e.hex })
                      }
                    }
                    }
                  />
                </InputField>
                : field.type === "boolean" ?
                  <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                    <Toggle
                      id={field.item}
                      checked={formState[field.item]}
                      onChange={(e) => {
                        formDispatch({ type: "FORM_INPUT_BOOLEAN", field: field.item })
                      }} />
                    <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                  </InputField>
                  : field.type === "multiple" ?
                    <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                      <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                      {field.options.map(opt => <><Toggle
                        id={opt}
                        checked={formState[field.item] && formState[field.item].includes(opt)}
                        onChange={(e) => formDispatch({ type: "FORM_INPUT_BOOLEAN_MULTI", field: field.item, option: opt })} />
                        <label for={opt}>{opt}:</label></>)}
                    </InputField>
                    : field.type === "gap" ?
                      <Gap {...{ ...field }}></Gap>
                      : field.type === "email" ?
                        <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                          <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                          <input type={field.type} id={field.item} name={field.item} value={formState[field.item]} onChange={(e) => formDispatch({ type: "FORM_INPUT", field: field.item, value: e.target.value })} />
                        </InputField>
                        :
                        <InputField key={i + field.item} className={emptyRequired.includes(field.item) ? "focus" : ""} {...{ ...field }}>
                          <label for={field.item}>{field.label ? field.label : field.item}{field.required ? "*" : ""}:</label>
                          <input type={field.type} id={field.item} name={field.item} value={formState[field.item]} onChange={(e) => formDispatch({ type: "FORM_INPUT", field: field.item, value: e.target.value })} />
                        </InputField>
      )}
      {SubmitBtnComponent ?
        <SubmitBtnComponent class="submit-btn" type="submit" value={submitText} onClick={onClickSubmit} />
        :
        <BlackButton class="submit-btn" type="submit" value={submitText} onClick={onClickSubmit} />
      }
    </ Form>
  )
}