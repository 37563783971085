import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";

import './App.css';
import ReactGA from "react-ga";


import Form from "./views/Form"
import Home from "./views/Home";
import ArtWorkDetails from './views/ArtWorkDetails';
import StoreArtworks from "./views/StoreArtwork";
import ArtwrokUpdate from "./views/ArtworkUpdate";
import StoreSeries from "./views/StoreSeries";
import ContactForm from "./views/ContactForm";


import useTimedPosesContext, { TimedPosesContext } from "./components/TimedPosesContext";
import useGlobalContext, { DataContext } from "./components/DataContext";
import AuthRoute from "./AuthRoute";
import TimedPoses from './views/TimedPoses';
import TimesPosesConfig from "./views/TimedPosesConfig";
import Heading from './components/Heading';
import GalleryOverview from './views/GalleryOverview';
import ThemeView from './views/ThemeView';
import Contact from "./views/ContactPopOver";
import About from "./views/About";
import SharePopOver from './components/SharePopOver';
import { ListItemIcon } from '@material-ui/core';
import TagView from './views/TagView';

import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = () => <></>



const themesSchema = [
  { item: "theme", type: "text" }
]
const seriesSchema = [
  { item: "serie", type: "text" },
  { item: "description", type: "textarea" },
]

const emailUnsubscriptionSchema = [
  { item: "email", type: "text" }
];

const emailSharingSchema = [
  { item: "subject", type: "text" },
  { item: "emailBody", type: "textarea" },
];

const RouteChangeTracker = () => {
  const history = useHistory()

  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return <></>;
};


const HeadingPlaceholder = styled.div`
  width: 1px;
  height: 140px;
`;


// ReactGA.initialize("UA-190331710-1", {
//   // debug: true,
//   titleCase: false,
//   gaOptions: {
//     siteSpeedSampleRate: 100
//   }
// });
// ReactGA.pageview(window.location.pathname + window.location.search);


function App() {

  const { globalContext, globalDispatch } = useGlobalContext();

  useEffect(() => {
    fetch("/images/entries")
      .then(data => data.json())
      .then(data => {
        console.log("got all them entries", data)
        globalDispatch({ type: "STORE_ENTRIES", entries: data.items })
      })

  }, [])

  useEffect(() => {
    fetch("/images/tags")
      .then(data => data.json())
      .then(data => {
        console.log("got all them tags", data);
        globalDispatch({ type: "STORE_TAGS", tags: data.items.map(item => item.name) })
      })

  }, [])

  useEffect(() => {
    fetch("/images/")
      .then(data => data.json())
      .then(data => {
        return data;
      })
      .then(data => globalDispatch({ type: "GET_IMAGES_LIST", list: data.items }))

  }, [])



  return (
    <DataContext.Provider value={{ globalContext, globalDispatch }}>
      <TimedPosesContext.Provider value={useTimedPosesContext()}>
        <Router>
          <RouteChangeTracker />
          <div>
            <Switch>

              <Route exact path="/timedReferences">
                <AuthRoute>
                  <Heading />
                  <TimesPosesConfig />
                </AuthRoute>
              </Route>
              <Route exact path="/timedReferences/timer">
                <AuthRoute>
                  <TimedPoses />
                </AuthRoute>
              </Route>
              <Route path="/emails/send">
                <AuthRoute>

                  <Form
                    requireLogin={true}
                    schema={emailSharingSchema}
                    onSubmit={(fields) => ({
                      postPath: "/emails/send",
                      init: {
                        method: "POST", headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(fields)
                      }
                    })}
                  />
                </AuthRoute>
              </Route>
              <Route path="/store/themes">
                <AuthRoute>
                  <Heading />
                  <HeadingPlaceholder />
                  <StoreSeries {...{
                    formConfig: {
                      postPath: "/artworks/store/themes",
                      schema: themesSchema,
                      requireLogin: true,
                      onSubmit: (fields) => ({
                        postPath: "/artworks/store/themes",
                        init: {
                          method: "POST", headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify(fields)
                        }
                      })
                    }
                  }} />
                </AuthRoute>
              </Route>
              <Route path="/store/series">
                <AuthRoute>
                  <Heading />
                  <HeadingPlaceholder />
                  <StoreSeries {...{
                    formConfig: {
                      postPath: "/artworks/store/series",
                      schema: seriesSchema,
                      requireLogin: true,
                      onSubmit: (fields) => ({
                        postPath: "/artworks/store/series",
                        init: {
                          method: "POST", headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify(fields)
                        }
                      })
                    }
                  }} />
                </AuthRoute>
              </Route>
              <Route path="/store/">
                <AuthRoute>
                  <Heading />
                  <HeadingPlaceholder />
                  <StoreArtworks />
                </AuthRoute>
              </Route>
              <Route path="/updatestore/">
                <AuthRoute>
                  <Heading />
                  <HeadingPlaceholder />
                  <ArtwrokUpdate />
                </AuthRoute>
              </Route>
              <Route exact path="/gallery">
                <>
                  <Heading />
                  <HeadingPlaceholder />
                  <GalleryOverview />
                </>
              </Route>
              <Route path="/gallery/">
                <>
                  <Heading />
                  <HeadingPlaceholder />
                  <TagView />
                </>
              </Route>
              <Route path="/about">
                <>
                  <Heading />
                  <HeadingPlaceholder />
                  <About />
                </>
              </Route>
              <Route path="/order">
                <ContactForm />
              </Route>
              <Route path="/artwork">
                <>
                  <Heading />
                  <HeadingPlaceholder />
                  <ArtWorkDetails />
                </>
              </Route>
              <Route path="/">
                <>
                  <Heading />
                  <Home />
                </>
              </Route>
            </Switch>
          </div>
        </Router>
      </TimedPosesContext.Provider>
      {globalContext.popOverOpen ?
        <SharePopOver />
        : <></>}
      {globalContext.contact ?
        <Contact closeOverlay={() => globalDispatch({ type: "TOGGLE_CONTACT" })} show={globalContext.contact} />
        : <></>}
    </DataContext.Provider>
  )


}

export default App;
