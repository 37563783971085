import React from "react";
import "./PhoneFlip.css"

export default () => <div className="center">

  <div className="smartphone">
    <content>
      <div></div>
      {/* <ul>
        <li></li>
        <li></li>
        <li></li>
        <li style={{ width: "90%" }}></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li style={{ width: "60%" }}></li>
        <li></li>
        <li></li>
        <li style={{ width: "70%" }}></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
    </content>
  </div></div>