import React from "react";
import { useReducer } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";

import DraggableList from "./DraggableList";
import { Thumbnail } from "./TimedPoses";

import { TimedPosesContext } from "../components/TimedPosesContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";



const UploadImages = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
`;

const BigThumbnail = styled(Thumbnail)`
  width: 80px;
  height: 80px;
`;

const AddImages = styled.div`
  width: 80px;
  height: 80px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const AddLabel = styled.label`
  cursor: pointer;
  padding: 5px 20px;
  font-size: 40px;
  font-weight: bold;
  background: salmon;
  color: wheat;

  &:hover{
    color: salmon;
    background: linear-gradient(270deg, #00ffbd, #ff8900, #defdf2, #f5b9c3);
    background-size: 800% 800%;
    -webkit-animation: BackgroundGradient 50s ease infinite;
    -moz-animation: BackgroundGradient 50s ease infinite;
    animation: BackgroundGradient 50s ease infinite;
  }
`;

const initialState = {
  images: [],
  interval: 2,
}

const reducer = (state, action) => {
  const actions = {
    "GET_POSES": () => ({
      ...state,
      images: [...state.images, action.image],
    }),
    "INPUT": () => ({
      ...state,
      [action.field]: action.value
    }),
    "REORDER_POSES": () => ({
      ...state,
      images: action.images,
    }),

    "default": () => ({ ...state })
  };


  return actions[action.type] ? actions[action.type]() : actions["default"]();
}

export default () => {

  // const [{ images, interval }, configDispatch] = useReducer(reducer, initialState);
  const { timedPosesState: { images, interval }, timedPosesDispatch } = useContext(TimedPosesContext);
  const history = useHistory();


  useEffect(() => { }, [images])

  function readURL(files) {
    if (files && files[0]) {
      var reader = new FileReader();

      let index = 0;

      reader.onload = function (e) {
        // $('#blah').attr('src', e.target.result);
        // setImages([...images, e.target.result]);
        timedPosesDispatch({ type: "GET_POSES", image: e.target.result })
        const nextIndex = index + 1
        if (files.length > nextIndex) {
          index = nextIndex;
          reader.readAsDataURL(files[nextIndex]);
        }
      }

      reader.readAsDataURL(files[index]); // convert to base64 string
    }
  }

  const thumbnails = useMemo(() => {
    let th = []
    if (images.length) {
      th = images.map((item, index) => ({
        id: "item-" + index,
        source: item,
        content: <>
          <BigThumbnail
            src={item}
          ></BigThumbnail>
        </>
      }))
    }
    return th;
  }, [images]);

  const storeSelectionLocally = () => {
    // localStorage.setItem("timedPoses", images);
    history.push("/timedReferences/timer");
  }

  return (
    <UploadImages>

      {thumbnails.length ?
        <>
          <input type="number" min="1" name="interval" id="interval" value={interval} onChange={e => {
            timedPosesDispatch({ type: "INPUT", field: "interval", value: e.target.value })
          }} />
          <DraggableList
            items={thumbnails}
            setItems={(images => timedPosesDispatch({ type: "REORDER_POSES", images }))}
          />
          <AddImages>

            <AddLabel for="fileAdd" >+</AddLabel>
            <HiddenInput type="file" name="fileField[]" id="fileAdd" multiple="multiple" onChange={e => {
              readURL(e.target.files)
            }}
            />
          </AddImages>
          <button onClick={storeSelectionLocally}>All Set</button>
        </>
        :
        <input type="file" name="fileField[]" id="fileField" multiple="multiple" onChange={e => {
          readURL(e.target.files)
        }}
        />
      }

    </UploadImages>
  )
}