import styled from "styled-components";

export const BlackButton = styled.input`
  background-color: #2b2b2b;
  color: rgba(251, 251, 251, 0.9);
  width: 100%;
  max-width: 250px;
  height: 52px;
  border-radius: 6px;
  line-height: 30px;
  font-size: 19px;
  font-family: 'Lato';
  cursor: pointer;
  margin: 0 auto;
  `;

