import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../components/DataContext";
import Draggable from 'react-draggable';

import List from "../fileList";
import Heading from "../components/Heading";
import Form from "./Form";
import { useHistory } from "react-router-dom";


const { FileList } = List;


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  padding: 50px;
  display: flex;
  flex-flow: row nowrap;
  background-color: ${props => props.backgroundColor};
`;

const Preview = styled.img`
  height: 400px;
  width: auto;
`;

const CropPreview = styled.img`
position: absolute;
  height: 400px;
  width: auto;
  clip-path: ${props => props.inset ? `inset(${props.inset})` : "none"};
  top: ${props => props.top ? props.top : 0};
  left: ${props => props.left ? props.left : 0};
  width: ${props => props.width ? `${props.width}px` : "auto"};
  height: ${props => props.height ? `${props.height}px` : "400px"};
  /* width: ${props => props.width ? `calc(100% + ${props.width}px)` : "auto"}; */
  /* height: ${props => props.height ? `calc(100% + 100% - ${props.height}px)` : "100%"}; */
  object-fit: cover;
  /* object-position: ${props => props.objectPosition}; */
`;

const PreviewContainer = styled.div`
  height: 400px;
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-top: 100px;
`;

const ColorPreview = styled.div`
  position: absolute;
  margin: -40px;
  background-color: ${props => props.backgroundColor};
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  z-index: -1;
`;

const Handle = styled.div`
  width: ${props => props.vertical ? "10px" : "100%"};
  height: ${props => props.vertical ? "100%" : "10px"};
  background-color: #2b402b;
  position: absolute;

  ${props => props.top ? `
    top: 0;
  `:
    `
    bottom: 0;
  `}
  ${props => props.left ? `
    left: 0;
  `:
    `
    right: 0;
  `
  }

`;

const SideBar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
`;

const CropBox = styled.div`
position: relative;
  width: auto;
  height: 400px;
  width: ${props => props.width ? props.width : "auto"};
  height: ${props => props.height ? props.height : "400px"};
`;

const ReturnButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`

const artworksInitial = {
  title: "",
  description: "",
  alt: "",
  theme: "",
  color: "",
  location: "",
  size: "",
  file: "",
  serie: "",
  price: "",
  datefinished: "",
}


const artworksSchema = [
  { item: "title", type: "text" },
  { item: "description", type: "textarea" },
  { item: "alt", type: "text" },
  { item: "theme", type: "select", options: [] },
  { item: "color", type: "text" },
  { item: "materials", type: "text" },
  { item: "dimensions", type: "text" },
  { item: "location", type: "text" },
  { item: "size", type: "select", options: ["xlarge", "large", "medium", "small", "xsmall"] },
  { item: "file", type: "select", options: [...FileList] },
  { item: "serie", type: "select", options: [] },
  { item: "price", type: "text" },
  { item: "datefinished", type: "text" },
]

export default ({ formConfig }) => {

  const { globalContext, globalDispatch } = useContext(DataContext);
  const [formState, setFormState] = useState();


  const history = useHistory();


  return (
    <Container {...{ backgroundColor: `#${formState ? formState.color : "transparent"}` }}>
      <ReturnButton onClick={() => history.push("/updatestore")} >Return</ReturnButton>
      <Form
        {...{ ...formConfig }}
      />
    </Container>
  )
}