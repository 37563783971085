import React, { useContext } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";

import { DataContext } from "../components/DataContext";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { animateScroll as scroll } from 'react-scroll'
import { deviceCheck, parseTagName } from "../utils";



export const Container = styled.div`
  width: 100vw;
  min-height: 100vh; 
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 10px;
  padding-bottom: 100px;
  height: ${props => props.containerHeight};
  min-height: 100vh;

  @media only screen and (min-width: 700px) {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    margin: 0 5vw;
    /* height: 100vw; */
    /* margin: ${props => `0 ${props.containerMargin}px`}; */
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
    margin: 0 15vw;
  }
`;

export const ImagesContainerv2 = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-grow:1;
  gap: 20px;
  padding: 0 20px;
  justify-content: flex-start;

  @media only screen and (min-width: 700px) {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    max-width: 50%;
    padding: 0;
  }
  @media only screen and (min-width: 1200px) {
  }
`;


export const ImageContainer = styled.div`
  position: relative;
  /* width: calc(100vw - 35px); */
  color: #444444;
  :hover{
    color: black;
   font-weight: 900; 
  }
  width: 100%;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 700px) {
  }
`;

export const StyledImage = styled.img`
  object-fit: contain;
  width: 100%;
  cursor: pointer;
  align-self: center;
  
`;


export const ThemeTitle = styled.p`
  /* position: absolute; */
  /* bottom: 0; */
  /* z-index: 2; */
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
  max-height: 100%;
  /* padding: 0; */
  text-align: start;
  /* line-break: strict; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  margin-bottom: 10px;
    margin-top: 30px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #00000052;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 100px;
  @media only screen and (min-width: 700px) {
    flex-flow: row nowrap;
    width: 90vw;
  }
  @media only screen and (min-width: 1200px) {
    width: 70vw;
  }
`;

{/* <ImageContainer
onClick={() => {
  scroll.scrollToTop({
    duration: 1000,
    smooth: true,
  });
  history.push("/gallery/" + sr.serie.replace(/ /g, "_"))
}}
>
<ThemeTitle>{sr.serie}</ThemeTitle>
{sr.artwork.url ?
  <StyledImage
    src={sr.artwork.url + `?fm=jpg&fl=progressive&w=${Math.round(window.innerWidth * 0.4)}`}
    alt={sr.artwork.alt}
  ></StyledImage>
  : <></>}
</ ImageContainer> */}

export default () => {
  const { globalContext: { tags, entries }, globalDispatch } = useContext(DataContext);

  useEffect(() => {
    globalDispatch({ type: "CHANGE_HEADER_COLOR", color: "var(--background-color)" });
  }, [])

  const history = useHistory();

  const imagesPerTag = useMemo(() => {
    if (entries.length && tags.length) {

      return tags.map(tag => {
        const filteredEntries = entries.filter(entry => entry.metadata.tags.find(entryTag => entryTag.sys.id === parseTagName(tag)));
        const randomIndex = filteredEntries.length ? Math.floor(Math.random() * (filteredEntries.length - 1)) : 0;
        if (randomIndex >= 0) {
          return { ...filteredEntries[randomIndex], tag };
        }
      }).filter(e => !!e);
    } else {
      return [];
    }
  }, [tags, entries])

  return (
    <Container>
      <Row>

        <ImagesContainerv2>
          {imagesPerTag.map((img, index) => index < imagesPerTag.length / 2 && <ImageContainer
            onClick={() => {
              scroll.scrollToTop({
                duration: 1000,
                smooth: true,
              });
              history.push("/gallery/" + img.tag.replace(/ /g, "_"))
            }}
          >
            <ThemeTitle>{img.tag}</ThemeTitle>

            <StyledImage
              loading="lazy"
              src={img.fields.image.fields.file.url + `?fm=jpg&fl=progressive&w=${deviceCheck() === "desktop" ? Math.round(window.innerWidth * 0.4) : window.innerWidth}`}
              alt={img.fields.description}
            ></StyledImage>

          </ ImageContainer>)}
        </ImagesContainerv2>
        <ImagesContainerv2>
          {imagesPerTag.map((img, index) => index >= imagesPerTag.length / 2 && <ImageContainer
            onClick={() => {
              scroll.scrollToTop({
                duration: 1000,
                smooth: true,
              });
              history.push("/gallery/" + img.tag.replace(/ /g, "_"))
            }}
          >
            <ThemeTitle>{img.tag}</ThemeTitle>

            <StyledImage
              loading="lazy"
              src={img.fields.image.fields.file.url + `?fm=jpg&fl=progressive&w=${deviceCheck() === "desktop" ? Math.round(window.innerWidth * 0.4) : window.innerWidth}`}
              alt={img.fields.description}
            ></StyledImage>

          </ ImageContainer>)}
        </ImagesContainerv2>
      </Row>
      <Footer />
    </Container >
  )
}